<template>
    <v-dialog v-model="dialog_send_invoice" fullscreen hide-overlay persistent>
        <v-card>
            <v-toolbar dark :color="$store.state.theme.primary">
                <v-btn icon dark @click="closeSendEmail">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Rechnungsversand</v-toolbar-title>
            </v-toolbar>
            <v-row class="mt-0 pa-5">
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                    <v-card outlined>
                        <v-card-title class="justify-center">
                            Bitte überprüfe die Rechnung vor dem Versenden der E-Mail
                            <v-btn @click="downloadInvoiceBlob" class="mt-2">
                                <v-icon left>mdi-arrow-down-bold-circle-outline</v-icon>
                                Rechnung herunterladen
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <object
                                v-if="current_invoice_url"
                                :data="current_invoice_url"
                                type="application/pdf"
                                width="100%"
                                :style="{ height: computedHeight + 'px' }">
                                <p>
                                    Rechnung kann nicht angezeigt werden. Lade die Rechnung
                                    <a :href="current_invoice_url">herunter</a> und überprüfe sie bevor du sie verschickst.
                                </p>
                            </object>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                    <v-card outlined>
                        <v-card-title class="justify-center"> E-Mail Details </v-card-title>
                        <v-container class="px-5">
                            <v-form v-model="valid_email">
                                <div class="d-flex align-items-center justify-center">
                                    <v-text-field dense outlined v-model="editedItem.email" label="E-Mail Adresse" :rules="emailRules" ref="recipient_email" />
                                    <v-checkbox
                                        :color="$store.state.theme.primary"
                                        v-model="send_to_me"
                                        label="Kopie an mich selbst senden (CC)"
                                        class="ml-5 my-auto py-0" />
                                </div>
                            </v-form>

                            <span class="text-h6 text--black mt-0 mb-3">E-Mail Nachricht</span>
                            <v-text-field class="mt-5 mb-0 pb-0" dense outlined v-model="subject" label="Betreff" />
                            <v-textarea v-model="draft_message" label="Nachricht" auto-grow outlined class="mb-0 pb-0"></v-textarea>

                            <v-alert class="mb-4 mt-0" dense text icon="mdi-paperclip"> Die Rechnung wird automatisch als PDF der E-Mail angehängt. </v-alert>

                            <p>
                                <span class="font-weight-bold">Tipp:</span> Die Vorlage für den Betreff und die Nachricht kann in den Einstellungen angepasst
                                werden.
                            </p>

                            <v-row no-gutters class="justify-center mt-2">
                                <v-btn
                                    :disabled="!valid_email"
                                    :color="$store.state.theme.primary"
                                    @click="sendInvoice"
                                    :dark="valid_email"
                                    :loading="sending_invoice">
                                    <v-icon left>mdi-paperclip</v-icon>
                                    Versenden
                                </v-btn>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import connector from "../helpers/supabase-connector.js";
import { saveAs } from "file-saver";
import { Buffer } from "buffer";
import invoices from "../helpers/invoices.js";

export default {
    emits: ["showError", "showInfo", "loadInvoices"],
    props: ["session"],
    data() {
        return {
            computedHeight: window.innerHeight,

            editedItem: {
                id: null,
                email: null,
                vorname: null,
                nachname: null,
                nummer: null,
            },
            dialog_send_invoice: false,
            current_invoice_url: null,
            send_to_me: true,
            subject: "Rechnung",
            draft_message: "Hallo {empfänger_vorname},\n\nanbei die aktuelle Rechnung.\n\nViele Grüße,\n{praxis_name}",
            sending_invoice: false,
            valid_email: false,
            emailRules: [(v) => !!v || "E-Mail Adresse ist erforderlich", (v) => /.+@.+\..+/.test(v) || "E-Mail Adresse ist ungültig"],
            current_invoice_blob: null,
            current_invoice_filename: null,
        };
    },
    computed: {},

    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },

    methods: {
        handleResize() {
            this.computedHeight = window.innerHeight - 250;
        },

        async openSendEmail(item, invoice_template) {
            try {
                let invoice_blob = await invoices.createInvoiceDocument(this, item, true, false, invoice_template);
                if (!("status" in invoice_blob && invoice_blob.status === "success")) {
                    return invoice_blob;
                }
                this.current_invoice_blob = invoice_blob.blob;
                this.current_invoice_url = URL.createObjectURL(invoice_blob.blob);
                this.current_invoice_filename = invoice_blob.filename;
                if (this.current_invoice_url) {
                    this.editedItem = Object.assign({}, item);

                    // we need to set it here, as we cannot do it in the DB as we would mix unencrypted and encrypted data types
                    if (this.editedItem.rechnungs_empfänger === 'institution' && (this.editedItem.email === null || this.editedItem.email === "") && this.editedItem.empfänger_email !== null) {
                        this.editedItem.email = this.editedItem.empfänger_email;
                    }

                    // set the default message and subject
                    this.subject = this.$store.state.client.email_betreff ? this.$store.state.client.email_betreff : "Rechnung";
                    this.draft_message = this.$store.state.client.email_nachricht
                        ? this.$store.state.client.email_nachricht
                        : "Hallo {empfänger_vorname},\n\nanbei die aktuelle Rechnung.\n\nViele Grüße,\n{praxis_name}";

                    // replace placeholders in the message
                    this.draft_message = this.draft_message
                        .replace(/{vorname}/gi, this.editedItem.vorname)
                        .replace(/{nachname}/gi, this.editedItem.nachname)
                        .replace(/{titel_vorgestellt}/gi, this.editedItem.titel_vorgestellt)
                        .replace(/{titel_nachgestellt}/gi, this.editedItem.titel_nachgestellt)
                        .replace(/{empfänger_vorname}/gi, this.editedItem.empfänger_vorname)
                        .replace(/{empfänger_nachname}/gi, this.editedItem.empfänger_nachname)
                        .replace(/{empfänger_titel_vorgestellt}/gi, this.editedItem.empfänger_titel_vorgestellt)
                        .replace(/{empfänger_titel_nachgestellt}/gi, this.editedItem.empfänger_titel_nachgestellt)
                        .replace(/{praxis_name}/gi, this.$store.state.client.name)
                        .replace(/[ \t]+(\r\n|\n|\r)/g, "$1");

                    this.dialog_send_invoice = true;
                    this.$nextTick(() => {
                        if ("recipient_email" in this.$refs) this.$refs["recipient_email"].validate(true);
                    });

                    return { status: "success" };
                }
            } catch (error) {
                this.$emit("showError", { message: "Fehler beim Erstellen der Rechnung. " + error });
                return { status: "error" };
            }
        },

        closeSendEmail() {
            this.dialog_send_invoice = false;
            this.current_invoice_blob = null;
            this.current_invoice_filename = null;

            // to clear cache
            if (this.current_invoice_url !== null) {
                URL.revokeObjectURL(this.current_invoice_url);
                this.current_invoice_url = null;
            }

            this.$nextTick(() => {
                this.editedItem = {
                    id: null,
                    email: null,
                    vorname: null,
                    nachname: null,
                    nummer: null,
                };
            });
        },

        async downloadInvoiceBlob() {
            await saveAs(this.current_invoice_blob, this.current_invoice_filename);
        },

        async sendInvoice() {
            if (this.editedItem.email === null || this.editedItem.email === "") {
                this.$emit("showError", { message: "Keine E-Mail Adresse angegeben." });
                return;
            }

            if (this.subject === "" || this.subject === null) {
                this.$emit("showError", { message: "Kein Betreff angegeben." });
                return;
            }

            if (this.draft_message === "" || this.draft_message === null) {
                this.$emit("showError", { message: "Keine Nachricht angegeben." });
                return;
            }

            // send invoice
            this.sending_invoice = true;

            let invoice_binary = Buffer.from(await this.current_invoice_blob.arrayBuffer());

            let email = {
                name: this.$store.state.client.name,
                recipient: this.editedItem.email.trim(),
                subject: this.subject,
                content: this.draft_message.trim(),
                reply_to: this.session.user.email,
                invoice_blob: invoice_binary,
                cc: this.send_to_me ? this.session.user.email : null,
            };

            try {
                let response = await invoices.sendInvoiceMail(this, email);

                if (response !== null && "status" in response && response.status) {
                    // mark invoice as sent
                    let toInsert = {
                        versendet: true,
                    };

                    let updated = await connector.update(this, "rechnungen", toInsert, this.editedItem.id);
                    if (updated === null) {
                        // error has already been shown
                        this.$emit('showError', {
                            message: 'Die E-Mail wurde erfolgreich versendet, jedoch konnte der Status des E-Mail Versands nicht gespeichert werden.',
                            timeout: 10000,
                        });
                    }
                    this.$emit("loadInvoices");
                    this.sending_invoice = false;
                    if (updated !== null) this.$emit("showInfo", { message: "Die Rechnung wurde erfolgreich versendet.", timeout: 5000 });
                } else {
                    this.$emit("showError", { message: "Die Rechnung konnte nicht versendet werden." });
                    this.sending_invoice = false;
                    return;
                }
            } catch (error) {
                this.$emit("showError", { 
                    message: "Fehler beim Versenden der E-Mail. Bitte versuche es erneut.",
                    timeout: 10000,
                    error: error 
                });
                this.sending_invoice = false;
                return;
            }
            this.closeSendEmail();
        },
    },
};
</script>
