<template>
    <v-container fluid>
        <v-dialog v-model="dialogDelete" max-width="600px" persistent>
            <v-card>
                <v-card-title class="text-h5">Soll dieser Eintrag wirklich gelöscht werden?</v-card-title>
                <v-card-actions>
                    <v-spacer />
                    <v-btn :color="$store.state.theme.red" :disabled="deleting_transaction" text @click="closeDelete">Nein</v-btn>
                    <v-btn :color="$store.state.theme.green" :loading="deleting_transaction" text @click="deleteItemConfirm">Ja</v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row no-gutters :class="$vuetify.breakpoint.xsOnly ? 'pt-3' : ''">
            <v-col class="d-flex align-center justify-space-between">
                <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Suche" single-line hide-details outlined dense clearable clear-icon="mdi-close-circle" />
                <v-btn v-if="$vuetify.breakpoint.smAndUp" class="ml-5" elevation="1" :color="$store.state.theme.green" @click="dialog = true" dark>
                    <v-icon left>mdi-plus</v-icon>
                    Neue Einnahme / Ausgabe
                </v-btn>
                <v-dialog v-model="dialog" persistent max-width="700" :fullscreen="$vuetify.breakpoint.xsOnly">
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text class="mt-3 mb-0 pb-0">
                            <v-row class="my-0 py-0">
                                <v-col class="my-0 py-0" :cols="editedItem.kategorie === enum_categories['Ausgaben'] && $vuetify.breakpoint.smAndUp ? 4 : 12">
                                    <v-select dense outlined v-model="editedItem.kategorie" :items="categories"
                                        item-text="text" item-value="id" label="Kategorie" @input="changedAmount(-1)" />
                                </v-col>
                                <v-col class="my-0 py-0" v-if="editedItem.kategorie === enum_categories['Ausgaben']">
                                    <v-select dense outlined v-model="editedItem.verteiler" :items="itemsExpenses"
                                        item-text="text" item-value="id" label="Verteiler" />
                                </v-col>
                            </v-row>
                            <div v-if="editedIndex === -1">
                                <v-row v-for="transaction in transactions_to_insert" :key="transaction.id" class="my-0 pt-0 pb-5 pb-sm-0">
                                    <v-col cols="12" sm="4" class="py-0 my-auto">
                                        <v-text-field dense outlined v-model="transaction.transaction.beschreibung"
                                            label="Beschreibung" />
                                    </v-col>
                                    <v-col cols="6" sm="4" class="my-0 py-0">
                                        <v-menu v-model="transaction.menu" :close-on-content-click="false" offset-y
                                            max-width="290px" min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field dense outlined :value="formatDate(transaction.transaction.datum)" label="Datum"
                                                    hint="" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                                                    v-on="on" />
                                            </template>
                                            <v-date-picker dense outlined first-day-of-week="1" v-model="transaction.transaction.datum" no-title
                                                @input="transaction.menu = false" />
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6" sm="4" class="d-flex my-0 py-0">
                                        <v-text-field dense outlined type="number"
                                            @input="changedAmount(transaction.id)" hide-details
                                            v-model="transaction.transaction.betrag" label="Betrag" />
                                        <v-btn
                                            v-if="transaction.id === transactions_to_insert.length && transactions_to_insert.length > 1"
                                            class="ml-2" icon @click="removeTransactionRow">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col v-if="transaction.id === transactions_to_insert.length" cols="12" class="my-0 py-0">
                                        <v-btn rounded elevation="0" small @click="addTransactionRow">
                                            <v-icon left>mdi-plus</v-icon>
                                            Weitere Transaktion
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-row v-else class="my-0 py-0">
                                <v-col cols="12" sm="4" class="my-1 py-0">
                                    <v-text-field dense outlined v-model="editedItem.beschreibung"
                                        label="Beschreibung" />
                                </v-col>
                                <v-col cols="6" sm="3" class="my-1 py-0">
                                    <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition"
                                        offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field dense outlined v-model="computedDateFormatted" label="Datum"
                                                hint="" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                                                v-on="on" />
                                        </template>
                                        <v-date-picker first-day-of-week="1" v-model="editedItem.datum" no-title
                                            @input="menu = false" />
                                    </v-menu>
                                </v-col>
                                <v-col cols="6" sm="5" class="my-1 py-0">
                                    <v-text-field dense outlined type="number" @input="changedAmount(null)"
                                        v-model="editedItem.betrag" label="Betrag" />
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions class="mt-0 pt-0 px-6 pb-5">
                            <v-spacer />
                            <v-btn :color="$store.state.theme.red" :disabled="inserting_transaction" text @click="close">
                                Abbrechen
                            </v-btn>
                            <v-btn :color="$store.state.theme.green" :loading="inserting_transaction" outlined @click="saveTransaction">
                                Speichern
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-fab-transition>
            <v-btn
                v-if="$vuetify.breakpoint.xsOnly"
                :color="$store.state.theme.green"
                dark
                fixed
                bottom
                right
                fab
                @click="dialog = true"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>
        <v-data-table 
            dense 
            :headers="headers" 
            :items="fullyFilteredTransactions" 
            :sort-by="sortItems"
            :items-per-page.sync="itemsPerPage"
            :footer-props="{
                'items-per-page-options': [5, 10, 25, 50, 100, -1],
            }"
            @update:sort-by="updateSortItems" 
            :sort-desc="sortByDesc" 
            @update:sort-desc="updateSortItemsDesc"
            class="elevation-1 mt-3 custom-header-height" 
            @update:items-per-page="saveItemsPerPage"
        >
            <template v-slot:header.datum="{ header }">
                <span>{{ header.text }}</span>
                <!-- Date range filter -->
                <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-badge 
                            :color="$store.state.theme.primary" 
                            overlap
                            :content="(startDate ? 1 : 0) + (endDate ? 1 : 0)" 
                            :value="!!(startDate || endDate)"
                        >
                            <v-btn icon v-bind="attrs" v-on="on" small class="mx-2">
                                <v-icon>{{ !!(startDate || endDate) ? 'mdi-filter' : 'mdi-filter-outline' }}</v-icon>
                            </v-btn>
                        </v-badge>
                    </template>
                    <v-card>
                        <v-card-title>Datum filtern</v-card-title>
                        <v-card-text>
                            <v-row>
                                <!-- Start Date Section -->
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="filterDateTextStart"
                                        label="Startdatum"
                                        prepend-inner-icon="mdi-calendar"
                                        @input="updateDatePicker('start')"
                                        outlined
                                        dense
                                        :rules="dateRule"
                                        clearable
                                        clear-icon="mdi-close-circle"
                                    ></v-text-field>
                                    <v-date-picker
                                        class="mt-2"
                                        first-day-of-week="1"
                                        v-model="startDate"
                                        no-title
                                        elevation="1"
                                        @input="(date) => updateTextField('start', date)"
                                        :full-width="$vuetify.breakpoint.smAndDown"
                                    ></v-date-picker>
                                </v-col>

                                <!-- End Date Section -->
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="filterDateTextEnd"
                                        label="Enddatum"
                                        prepend-inner-icon="mdi-calendar"
                                        @input="updateDatePicker('end')"
                                        outlined
                                        dense
                                        :rules="dateRule"
                                        clearable
                                        clear-icon="mdi-close-circle"
                                    ></v-text-field>
                                    <v-date-picker
                                        class="mt-2"
                                        first-day-of-week="1"
                                        v-model="endDate"
                                        no-title
                                        elevation="1"
                                        @input="(date) => updateTextField('end', date)"
                                        :full-width="$vuetify.breakpoint.smAndDown"
                                    ></v-date-picker>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions v-if="endDate || startDate" class="px-4 pb-4">
                            <v-btn text block @click="clearDateFilter">Filter entfernen</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </template>
            <template v-slot:top>
                <v-tabs dense show-arrows color="grey darken-4" :background-color="$store.state.theme.background_tabs">
                    <v-tabs-slider :color="$store.state.theme.primary"></v-tabs-slider>
                    <v-tab @click="filterAll">
                        Alle <v-badge inline :content="nAllTransactions.toString()" :color="$store.state.theme.primary" />
                    </v-tab>
                    <v-tab @click="filterIncome">
                        Einnahmen <v-badge inline :content="nIncomeTransactions.toString()" :color="$store.state.theme.green" />
                    </v-tab>
                    <v-tab @click="filterSpendings">
                        Ausgaben <v-badge inline :content="nSpendingTransactions.toString()" :color="$store.state.theme.red" />
                    </v-tab>
                </v-tabs>
                <v-divider/>
            </template>
            <template v-slot:item.betrag="{ item }">
                <span v-if="item.betrag > 0" class="green--text font-weight-medium">
                    {{ item.betrag.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                </span>
                <span v-else class="font-weight-medium">
                    {{ item.betrag.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                </span>
            </template>
            <template v-slot:item.datum="{ item }">
                <span>{{ new Date(item.datum).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit' })
                }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn v-if="item.deletable !== false" class="mx-1" elevation="0" fab text x-small
                    :color="$store.state.theme.primary" @click="editItem(item)">
                    <v-icon small dark>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="item.deletable !== false" class="mx-1" elevation="0" fab text x-small color="#f44336"
                    @click="deleteItem(item)">
                    <v-icon small dark>mdi-delete</v-icon>
                </v-btn>
            </template>
            <template v-slot:body.append>
                <tr v-if="isFilterActive">
                    <td class="text-right" colspan="3">
                        Summe aller gefilterten Einträge: 
                        <span class="font-weight-bold">{{ sumOfBetrag.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}</span>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
            </template>
            <template v-slot:no-data>
                <div v-if="loading" class="text-center">
                    <v-progress-circular indeterminate color="primary" />
                </div>
                <div v-else>
                    <span class="mt-5">Bisher wurden keine Einnahmen oder Ausgaben erfasst.</span>
                </div>
            </template>
        </v-data-table>

        <v-row class="mt-5">
            <v-col class="d-flex align-center">
                <span class="mr-3 text-subtitle-1 text-sm-h5">Jahresübersicht</span>
                <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small elevation="0" text v-bind="attrs" v-on="on">
                        
                        <span class="mr-2 text-body-1">
                            {{ $store.state.selected_year }}
                        </span>
                        <v-icon>mdi-calendar-search</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="text-overline pb-0">Jahr</v-card-title>
                        <v-card-text class="mb-0 pb-0 px-5">
                        <v-radio-group v-model="$store.state.selected_year" class="my-0 pb-0" @change="changedYear">
                            <v-radio v-for="year in $store.state.active_years" :key="year.jahr" :label="year.jahr.toString()" :value="year.jahr" :color="$store.state.theme.primary" class="mt-1" />
                        </v-radio-group>
                        </v-card-text>
                    </v-card>
                </v-menu>
            </v-col>

            <v-col class="d-flex justify-end align-center">
                <v-btn @click="loadAndExportExcel($store.state.selected_year)" outlined :small="$vuetify.breakpoint.xsOnly" :disabled="loading">
                    <v-icon left>mdi-microsoft-excel</v-icon>
                    {{ $vuetify.breakpoint.smAndUp ? 'Buchhaltung exportieren' : 'Export' }}
                </v-btn>
            </v-col>
        </v-row>

        <!-- Jahressummen als separate Karten -->
        <v-row class="mt-1">
            <v-col cols="12" md="6">
                <v-card elevation="1" class="pa-2" style="height: 100%;">
                    <v-card-title class="d-flex align-center justify-center pt-1 pb-2">
                        <v-icon left :color="$store.state.theme.green">mdi-plus-minus-variant</v-icon>
                        <span class="ml-2 text-subtitle-1">Einnahmen / Ausgaben</span>
                    </v-card-title>
                    <v-card-text class="text-body-1 pa-1">
                        <v-row class="my-0">
                            <v-col cols="6" class="text-right my-0 py-0">
                                Einnahmen: 
                            </v-col>
                            <v-col cols="6" class="d-flex align-center justify-start my-0 py-0">
                                <v-icon left small :color="$store.state.theme.green">mdi-plus</v-icon><span class="green--text font-weight-medium">{{ computedEarnings.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="my-0">
                            <v-col cols="6" class="text-right my-0 py-0">
                                Ausgaben:
                            </v-col>
                            <v-col cols="6" class="d-flex align-center justify-start my-0 py-0">
                                <v-icon left small :color="$store.state.theme.red">mdi-minus</v-icon><span class="red--text font-weight-medium">{{ computedSpendings.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="my-0">
                            <v-col cols="6" class="text-right my-0 py-0">
                                {{ (computedEarnings - computedSpendings) >= 0 ? 'Gewinn:' : 'Verlust:' }}
                            </v-col>
                            <v-col cols="6" class="d-flex align-center justify-start my-0 py-0">
                                <v-icon left small>mdi-equal</v-icon><span class="font-weight-medium">{{ (computedEarnings - computedSpendings).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}</span>
                            </v-col>
                        </v-row>
                        <div v-if="computedEarningsCash > 0 || computedEarningsPerTax.behandlung > 0 || computedEarningsPerTax.supervision > 0 || computedEarningsPerTax.kleinunternehmer > 0 || computedEarningsPerTax.sonstige > 0" class="d-flex align-center">
                            <v-divider class="my-5"></v-divider>
                            <span class="mx-2 text-subtitle-1 font-weight-medium">Art der Umsätze</span>
                            <v-divider class="my-5"></v-divider>
                        </div>

                        <v-row v-if="computedEarningsCash > 0" class="my-0">
                            <v-col cols="7" sm="6" class="d-flex align-center justify-end my-0 py-0">
                                <v-icon small left>mdi-cash-register</v-icon>
                                <span>Umsatz in Bar:</span>
                            </v-col>
                            <v-col cols="5" sm="6" class="text-left my-0 py-0">
                                {{ computedEarningsCash.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                            </v-col>
                        </v-row>

                        <v-row v-if="computedEarningsPerTax.behandlung > 0" class="my-0">
                            <v-col cols="7" sm="6" class="d-flex justify-end align-center my-0 py-0">
                                <v-tooltip bottom open-delay="300">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon small left v-bind="attrs" v-on="on">mdi-stethoscope</v-icon>
                                        Behandlung:
                                    </template>
                                    <span>Umsatzsteuerbefreiung nach § 6 Abs. 1 Z 19 UstG (Behandlung).</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="5" sm="6" class="text-left my-0 py-0">
                                {{ computedEarningsPerTax.behandlung.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                            </v-col>
                        </v-row>
                        <v-row v-if="computedEarningsPerTax.supervision > 0" class="my-0">
                            <v-col cols="7" sm="6" class="d-flex justify-end align-center my-0 py-0">
                                <v-tooltip bottom open-delay="300">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon small left v-bind="attrs" v-on="on">mdi-school-outline</v-icon>
                                        Supervision:
                                    </template>
                                    <span>Umsatzsteuerbefreiung nach § 6 Abs. 1 Z 11 UstG (Ausbildungssupervision in einer Ausbildungseinrichtung).</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="5" sm="6" class="text-left my-0 py-0">
                                {{ computedEarningsPerTax.supervision.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                            </v-col>
                        </v-row>
                        <v-row v-if="computedEarningsPerTax.kleinunternehmer > 0" class="my-0">
                            <v-col cols="7" sm="6" class="d-flex justify-end align-center my-0 py-0">
                                <v-tooltip bottom open-delay="300">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon small left v-bind="attrs" v-on="on">mdi-account-tie-outline</v-icon>
                                        Kleinunternehmer:
                                    </template>
                                    <span>Umsatzsteuerbefreiung nach § 6 Abs. 1 Z 27 UstG (Kleinunternehmer).</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="5" sm="6" class="text-left my-0 py-0">
                                {{ computedEarningsPerTax.kleinunternehmer.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                            </v-col>
                        </v-row>
                        <v-row v-if="computedEarningsPerTax.sonstige > 0" class="my-0">
                            <v-col cols="7" sm="6" class="d-flex justify-end align-center my-0 py-0">
                                <v-tooltip bottom open-delay="300">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon left v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                                        Nicht zugeordnet:
                                    </template>
                                    <span>Diese Umsätze wurden nicht zugeordnet. Dabei kann es sich um Dienstleistungen ohne zugeordnete Steuerbefreiung oder um sonstige Einnahmen in der Buchhaltung handeln.</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="5" sm="6" class="text-left my-0 py-0">
                                {{ computedEarningsPerTax.sonstige.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card elevation="1" class="pa-2 d-flex flex-column" style="height: 100%;">
                    <v-card-title class="d-flex align-center justify-center pt-1 pb-2 flex-grow-0">
                        <v-icon left :color="$store.state.theme.red">mdi-cart-variant</v-icon>
                        <span class="ml-2 text-subtitle-1">Ausgaben-Verteilung</span>
                    </v-card-title>
                    <v-card-text class="pa-1 d-flex align-center justify-center flex-grow-1">
                        <apexchart v-show="donutChartSeries.length > 0" ref="donutChart" height="210" type="donut" :options="donutChartOptions" :series="donutChartSeries" style="width: 100%;" />
                        <v-sheet v-if="donutChartSeries.length === 0" class="d-flex flex-column justify-center align-center">
                            <v-icon x-large>mdi-emoticon-happy-outline</v-icon>
                            <span class="mt-2">Aktuell keine Ausgaben.</span>
                        </v-sheet>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- Chart -->
        <v-card class="mt-3" elevation="1">
            <v-sheet elevation="0" class="pt-1">
                <apexchart ref="chart" height="350" type="bar" color="red" :options="options" :series="series"></apexchart>
            </v-sheet>
        </v-card>
    </v-container>
</template>

<script>
import connector from '../helpers/supabase-connector.js'
import cipher from '../helpers/cipher.js'
import apexchart from 'vue-apexcharts'
import dayjs from 'dayjs';
import XLSX from 'xlsx';

import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default {
    props: ['session'],
    components: {
        apexchart
    },

    data() {
        return {
            dateRule: [
                v => (v === null || v === '') || /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(\d{4})$/.test(v) || 'Datum im Format TT.MM.JJJJ angeben.',
            ],

            startDate: null,
            endDate: null,

            filterDateTextStart: null,
            filterDateTextEnd: null,

            itemsPerPage: parseInt(localStorage.getItem('buchhaltung-items-per-page')) || 10,

            donutChartOptions: {
                colors: [
                    "#0073e6",
                    "#905f78",
                    "#009eb0",
                    "#9b8bf4",
                    "#606ff3",
                    "#bb87a1",
                    "#376690",
                    "#608ebb",
                ],

                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false,
                    fontSize: '14px',
                    horizontalAlign: 'left',
                    position: 'right'
                },
                tooltip: {
                    y: {
                        formatter: function (value) {
                            return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
                        }
                    }
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                value: {
                                    show: true,
                                    fontSize: '16px', // Schriftgröße für den Betrag
                                },
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: 'Gesamt',
                                    fontSize: '16px', 
                                    formatter: function (w) {
                                        const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);

                                        // check if seriesTotal contains three values
                                        // if so, return total value
                                        // if not, return nothing
                                        if (w.globals.seriesTotals.length > 0) {
                                            return `${total.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}`;
                                        } else {
                                            return '0€';
                                        }
                                    },
                                }
                            }
                        },
                        
                    }
                },
                labels: [],
                chart: {
                    type: 'donut',
                },
                responsive: [{
                breakpoint: 480,
                    options: {
                        chart: {
                            width: 320 // Adjust the width as needed
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
            },
            donutChartSeries: [], // Beispielwerte für die Kategorien

            deleting_transaction: false,
            inserting_transaction: false,

            filter_income: false,
            filter_spending: false,

            headers: [
                { text: 'Datum', value: 'datum' },
                { text: 'Beschreibung', value: 'beschreibung' },
                { text: 'Betrag', value: 'betrag', align: "right" },
                { text: 'Kategorie', value: 'sortKategorie'},
                { text: 'Aktionen', value: 'actions', align: "center", sortable: false },
            ],
            search: '',
            sortItems: ['datum', 'inserted_at'],
            sortByDesc: [true, true],
            editedIndex: -1,
            editedItem: {
                id: null,
                uid: this.session.user.id,
                datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
                beschreibung: null,
                betrag: null,
                kategorie: 2, // = Ausgaben
                verteiler: null,
            },
            defaultItem: {
                id: null,
                uid: this.session.user.id,
                datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
                beschreibung: null,
                betrag: null,
                kategorie: 2, // = Ausgaben
                verteiler: null,
            },

            transactions_to_insert: [
                {
                    id: 1,
                    transaction: { // defaultItem
                        id: null,
                        uid: this.session.user.id,
                        datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
                        beschreibung: null,
                        betrag: null,
                        kategorie: 2, // = Ausgaben
                        verteiler: null,
                    },
                    menu: false,
                }
            ],

            enum_categories: {
                //'Einbuchung': 0,
                'Einnahmen': 1,
                'Ausgaben': 2,
                // 'Miete': 3,
                // 'Rücklagen - Weglegung': 4,
                // 'SVS Teilzahlung': 5,
                // 'SVS Nachzahlung aus Rücklagen': 6,
                // 'Einbuchung - Rücklagen': 7,
                // 'Einbuchung - SVS': 8,
                // 'Einbuchung': 9,
                //'Persönliche Gehaltszahlung': 10,
                //'SVS Quartalszahlung': 11,
                //'Eigenübertrag': 12,
            },

            categories_mapping: {
                0: 'Einbuchung',
                1: 'Einnahmen',
                2: 'Ausgaben',
                // 3: 'Miete',
                // 4: 'Rücklagen - Weglegung',
                // 5: 'SVS Teilzahlung',
                // 6: 'SVS Nachzahlung aus Rücklagen',
                //7: 'Einbuchung', // legacy rücklagen
                //8: 'Einbuchung', // legacy svs
                9: 'Einbuchung', // legacy konto
                10: 'Persönliche Gehaltszahlung',
                11: 'SVS Quartalszahlung',
                12: 'Eigenübertrag',
            },
            default_category: { id: 2, text: 'Ausgaben' },
            categories: [
                { id: 2, text: 'Ausgaben' },
                { id: 1, text: 'Sonstige Einnahmen' },
                //{id: 12, text: 'Eigenübertrag' },   
                //{id: 10, text: 'Persönliche Gehaltszahlung' },
            ],

            itemsExpenses: [
                { id: 1, text: 'Abschreibung als Anlage (AfA)' },
                { id: 2, text: 'Arbeitsmittel / Büro' },
                { id: 3, text: 'Arbeitszimmer' },
                //{ id: 4, text: 'Bewirtung' },
                //{ id: 5, text: 'EDV-Aufwand' },
                { id: 6, text: 'Fachliteratur' },
                { id: 7, text: 'GWG (geringw. Wirtschaftsgut)' },
                //{ id: 8, text: 'KFZ-Aufwand' },
                { id: 9, text: 'Miete' },
                { id: 10, text: 'Porto' },
                { id: 11, text: 'Rechtsberatung' },
                { id: 12, text: 'Reisekosten' },
                { id: 13, text: 'Sonstiges' },
                //{ id: 14, text: 'Spenden' },
                { id: 15, text: 'Steuerberatung' },
                { id: 16, text: 'SVS' },
                { id: 17, text: 'Telefon' },
                { id: 21, text: 'Therapiematerial'},
                { id: 20, text: 'Versicherung'},
                { id: 18, text: 'Weiterbildung' },
                { id: 19, text: 'Werbungskosten' },
            ],

            transactions: [],
            invoices: [],
            dialog: false,
            dialogDelete: false,
            menu: false,
            loading: false,

            chart_default_values: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
            },

            options: {
                chart: {
                    id: 'vuechart-overview',
                    toolbar: {
                        show: false
                    },
                    stacked: false,
                },
                xaxis: {
                    categories: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
                },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                            if (val === undefined || val === null) {
                                return ''; // or return a default value like '0'
                            }
                            return val.toFixed(0); // Limits the decimal places to 2
                        }
                    }
                },
                fill: {
                    colors: [],
                },
                colors: [],
                dataLabels: {
                    enabled: !this.$vuetify.breakpoint.xsOnly,
                    formatter: function (val) {
                        if (val === undefined || val === null) {
                            return ''; // or return a default value like '0'
                        }
                        if (Number.isInteger(val)) {
                            return val.toString(); // Display as is if it's an integer
                        } else {
                            return val.toFixed(2); // Limit the decimal places to 2 if it's not an integer
                        }
                    }
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            // Check if the value is an integer
                            if (val === undefined || val === null) {
                                return ''; // or return a default value like '0'
                            }
                            if (Number.isInteger(val)) {
                                return val.toString(); // Display as is if it's an integer
                            } else {
                                return val.toFixed(2); // Limit the decimal places to 2 if it's not an integer
                            }
                        }
                    }
                },
                annotations: {
                    points: [],
                },
                title: {},

            },
            series: [],
        };
    },

    watch: {
      '$vuetify.breakpoint.xsOnly': function (val) {
        if (this.$refs.chart) {
            this.$refs.chart.updateOptions({
                dataLabels: {
                    enabled: !val
                    }
                });
        }
      }
    },

    methods: {

        updateDatePicker(type) {
            if (type === 'start') {
                const parsedDate = dayjs(this.filterDateTextStart, 'DD.MM.YYYY', true);
                if (parsedDate.isValid()) {
                    this.startDate = parsedDate.format('YYYY-MM-DD');
                } else {
                    this.startDate = null;
                }
            } else {
                const parsedDate = dayjs(this.filterDateTextEnd, 'DD.MM.YYYY', true);
                if (parsedDate.isValid()) {
                    this.endDate = parsedDate.format('YYYY-MM-DD');
                } else {
                    this.endDate = null;
                }
            }
        },

        formatDate(date) {
            if (!date) return null;
            return dayjs(date).format('DD.MM.YYYY');
        },

        updateTextField(type, newDate) {
            if (type === 'start') {
                this.filterDateTextStart = this.formatDate(newDate);
            } else {
                this.filterDateTextEnd = this.formatDate(newDate);
            }
        },

        clearDateFilter() {
            this.startDate = null;
            this.endDate = null;
            this.filterDateTextStart = null;
            this.filterDateTextEnd = null;
        },

        saveItemsPerPage(value) {
            localStorage.setItem('buchhaltung-items-per-page', value.toString());
        },

        getCategoryDisplayText(item) {
            let categoryString = this.categories_mapping[item.kategorie] || '';
            const verteilerText = this.itemsExpenses.find(expense => expense.id === item.verteiler)?.text;
            
            if (verteilerText) {
                categoryString = `${categoryString} (${verteilerText})`;
            }
            
            return categoryString;
        },

        filterAll() {
            this.filter_income = false;
            this.filter_spending = false;
        },

        filterIncome() {
            this.filter_income = true;
            this.filter_spending = false;
        },
        filterSpendings() {
            this.filter_income = false;
            this.filter_spending = true;
        },

        dateFilter(value, search, item) {

            // Konvertieren Sie das Datum in einen String im Format 'MM.yyyy'
            const dateString = new Date(item.datum).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit' });
            let categoryString = this.categories_mapping[item.kategorie];

            if (item.verteiler) {
                categoryString = categoryString + ' (' + this.itemsExpenses.find((expense) => expense.id === item.verteiler).text + ')' ;
            } 
            
            // Überprüfen Sie, ob der Suchtext im Datum oder in einem anderen Feld enthalten ist
            return value != null &&
                   search != null &&
                   typeof value === 'string' && (
                        value.toString().toLowerCase().includes(search.toLowerCase()) || 
                        dateString.includes(search) ||
                        categoryString.toLowerCase().includes(search.toLowerCase())
                    );
        },

        addTransactionRow() {
            this.transactions_to_insert.push(
                {
                    id: this.transactions_to_insert.length + 1,
                    transaction: Object.assign({}, this.defaultItem),
                    menu: false,
                }
            )
        },

        removeTransactionRow() {
            this.transactions_to_insert.pop()
        },

        getFormatedDate(date) {
            return this.formatDate(date)
        },

        changedYear() {
            localStorage.selected_year = parseInt(this.$store.state.selected_year)

            this.prepareChartData(this.invoices, this.transactions)
        },

        changedAmount(id) {
            if (this.editedItem.kategorie !== this.enum_categories['Ausgaben']) this.editedItem.verteiler = null

            if (id && id >= 0) {
                // here multiple transactions are edited
                // find the transaction with the id and change the amount in the transactions_to_insert array
                for (let i in this.transactions_to_insert) {
                    if (this.transactions_to_insert[i].id === id) {

                        if ([this.enum_categories['Ausgaben']].includes(this.editedItem.kategorie)) {
                            if (this.transactions_to_insert[i].transaction.betrag > 0) this.transactions_to_insert[i].transaction.betrag = -this.transactions_to_insert[i].transaction.betrag
                        } else {
                            if (this.transactions_to_insert[i].transaction.betrag < 0) this.transactions_to_insert[i].transaction.betrag = Math.abs(this.transactions_to_insert[i].transaction.betrag)
                        }
                        break
                    }
                }


            } else if (id === -1) {
                // here we changed the category, now change the transactions_to_insert array

                for (let i in this.transactions_to_insert) {

                    if ([this.enum_categories['Ausgaben']].includes(this.editedItem.kategorie)) {
                        if (this.transactions_to_insert[i].transaction.betrag > 0) this.transactions_to_insert[i].transaction.betrag = -this.transactions_to_insert[i].transaction.betrag
                    } else {
                        if (this.transactions_to_insert[i].transaction.betrag < 0) this.transactions_to_insert[i].transaction.betrag = Math.abs(this.transactions_to_insert[i].transaction.betrag)
                    }
                }

                // and also the single one
                if ([this.enum_categories['Ausgaben']].includes(this.editedItem.kategorie)) {
                    if (this.editedItem.betrag > 0) this.editedItem.betrag = -this.editedItem.betrag
                } else {
                    if (this.editedItem.betrag < 0) this.editedItem.betrag = Math.abs(this.editedItem.betrag)
                }

            } else {
                // here only one transaction is edited
                if ([this.enum_categories['Ausgaben']].includes(this.editedItem.kategorie)) {
                    if (this.editedItem.betrag > 0) this.editedItem.betrag = -this.editedItem.betrag
                } else {
                    if (this.editedItem.betrag < 0) this.editedItem.betrag = Math.abs(this.editedItem.betrag)
                }
            }


        },

        async downloadAccountingTemplate() {

            let bucket = 'public-templates'
            let filename = 'jahresabschluss.xlsx'
            let path = 'accounting/'
            let content = await connector.downloadFileFromBucket(this, bucket, path, filename);
            return content;

        },

        async loadAndExportExcel(year) {
            try {

                // Load the template
                const arrayBuffer = await this.downloadAccountingTemplate();

                // Parse the loaded file
                const workbook = XLSX.read(arrayBuffer, { cellStyles: true });

                // Remove workbook protection
                // if (workbook.Workbook && workbook.Workbook.WBProps) {
                //     workbook.Workbook.WBProps.lockRevision = false;
                //     workbook.Workbook.WBProps.lockStructure = false;
                //     workbook.Workbook.WBProps.lockWindows = false;
                // }

                // // Remove worksheet protection for each sheet
                // workbook.SheetNames.forEach(sheetName => {
                //     const worksheet = workbook.Sheets[sheetName];
                //     if (worksheet["!protect"]) {
                //         delete worksheet["!protect"];
                //     }
                // });

                // Get the first sheet (adjust as needed)
                //#endregion//const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets["Einnahmen"];

                // using the transactionsFromInvoices, add the following data to the excel file
                // - date
                // - description
                // - amount
                // - hard coded 0 for the tax
                // - formula for the net amount (amount - tax)

                let tableData = [];
                let income = 0;
                let spendings = 0;

                this.transactionsFromInvoices.filter((invoice) => dayjs(invoice.datum).year() === year)
                    .map((transaction) => {
                        return [
                            this.getFormatedDate(transaction.datum),
                            transaction.nummer,
                            transaction.betrag,
                            0,
                            //{t: "n", v: transaction.betrag, f: 'C' + (tableData.length + 2) + '-D' + (tableData.length + 2)}
                        ]
                    })
                    .concat(
                        this.transactions.filter((transaction) => (dayjs(transaction.datum).year() === year) && (transaction.kategorie === 1)) // sonstige Einnahmen
                            .map((transaction) => {
                                return [
                                    this.getFormatedDate(transaction.datum),
                                    transaction.beschreibung,
                                    transaction.betrag,
                                    0,
                                    //{t: "n", v: transaction.betrag, f: 'C' + (tableData.length + 2) + '-D' + (tableData.length + 2)}
                                ]
                            })
                    ).sort((a, b) => dayjs(a[0], "DD.MM.YYYY") - dayjs(b[0], "DD.MM.YYYY")).forEach((transaction) => {
                        tableData.push(transaction)
                    }
                    );

                // iterate through the tableData and add the formulas for the net amount
                tableData.forEach((row, index) => {
                    row.push({ t: "n", v: row[2], f: 'C' + (index + 2) + '-D' + (index + 2) })
                })

                // calculate the sum of the income
                income = tableData.reduce((acc, row) => acc + row[2], 0)

                XLSX.utils.sheet_add_aoa(worksheet, tableData, { origin: "A2" });


                const worksheet_spendings = workbook.Sheets["Ausgaben"];
                tableData = [];

                this.transactions.filter((transaction) => (dayjs(transaction.datum).year() === year) && (transaction.kategorie === 2)).sort((a, b) => dayjs(a.datum) - dayjs(b.datum)).forEach((transaction) => {
                    tableData.push([
                        this.getFormatedDate(transaction.datum),
                        transaction.beschreibung,
                        -transaction.betrag,
                        0,
                        { t: "n", v: -transaction.betrag, f: 'C' + (tableData.length + 2) + '-D' + (tableData.length + 2) },
                        this.itemsExpenses.find((item) => item.id === transaction.verteiler).text
                    ])
                });

                // calculate the sum of the spendings
                spendings = tableData.reduce((acc, row) => acc + row[2], 0)

                XLSX.utils.sheet_add_aoa(worksheet_spendings, tableData, { origin: "A2" });


                const worksheet_overview = workbook.Sheets["Übersicht"];

                tableData = [];

                if (year >= 2024) {
                    tableData.push('abzüglich Grundfreibetrag 15%')
                    tableData.push('')
                    tableData.push({ t: "n", v: (income - spendings) > 33000 ? -4950 : (income - spendings) > 0 ? -(income - spendings) * 0.15 : 0, f: 'IF(D22>33000,-4950,IF(D22>0,-D22*0.15,0))', z: '#,##0.00 "€";-#,##0.00 "€"' })
                } else if (year >= 2022) {
                    tableData.push('abzüglich Grundfreibetrag 15%')
                    tableData.push('')
                    tableData.push({ t: "n", v: (income - spendings) > 30000 ? -4500 : (income - spendings) > 0 ? -(income - spendings) * 0.15 : 0, f: 'IF(D22>30000,-4500,IF(D22>0,-D22*0.15,0))', z: '#,##0.00 "€";-#,##0.00 "€"' })
                } else {
                    tableData.push('abzüglich Grundfreibetrag 13%')
                    tableData.push('')
                    tableData.push({ t: "n", v: (income - spendings) > 30000 ? -3900 : (income - spendings) > 0 ? -(income - spendings) * 0.13 : 0, f: 'IF(D22>30000,-3900,IF(D22>0,-D22*0.13,0))', z: '#,##0.00 "€";-#,##0.00 "€"' })
                }

                XLSX.utils.sheet_add_aoa(worksheet_overview, [tableData], { origin: "B23" });

                XLSX.writeFile(workbook, "Jahresabschluss_ZEIPSY_" + year + ".xlsx", { compression: false, cellStyles: true });
            } catch (error) {
                console.error("Error processing Excel file:", error);
                // emit error
                this.$emit('showError', { message: error });
            }
        },

        updateSortItems(sortBy) {
            // to correclty sort items when there are two transactions on the same day.
            if (sortBy.includes('datum')) {
                sortBy.push('updated_at')
            } else {
                sortBy = sortBy.filter((item) => item !== 'updated_at')
            }
            this.sortItems = sortBy
        },

        updateSortItemsDesc(sortByDesc) {
            if (this.sortItems.length === 0) {
                sortByDesc = []
            } else if (this.sortItems.length === 2) {
                sortByDesc = [sortByDesc[0], sortByDesc[0]]
            }
            this.sortByDesc = sortByDesc
        },

        editItem(item) {
            this.editedIndex = item.id
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = item.id
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        async deleteItemConfirm() {
            this.deleting_transaction = true;
            let deleted = await connector.deleteRow(this, 'transaktionen', 'id', this.editedIndex);
            if (!deleted) {
                this.deleting_transaction = false;
                return;
            }
            await this.initialize();
            this.deleting_transaction = false;
            this.closeDelete();
        },

        close() {
            this.transactions_to_insert = [
                {
                    id: 1,
                    transaction: Object.assign({}, this.defaultItem),
                    menu: false,
                }
            ]
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        async initialize() {
            this.loading = true

            let active_years = await connector.getDataOnly(this, 'vwaktivejahre', 'jahr', false);
            if (active_years !== -1) {
                // error has already been display in case of -1
                // but if we cannot fetch, we just do not set it and hope that it is already set in store.
                // otherwise the default with [] is used.
                this.$store.state.active_years = active_years;
            }
            
            let transactions = await connector.getDataOnly(this, 'vwtransaktionen', ['datum', 'inserted_at'], false);
            if (transactions !== -1) {
                // same here, only update on succesfull retrieval, otherwise do not modify the value.
                this.transactions = transactions;
            }

            let invoices = await connector.getDataOnly(this, 'vwrechnungen', 'datum', false);
            // here we do not check for -1, as the decryptDataAsync function does that.
            this.invoices = await cipher.decryptDataSync(this, invoices);
            this.loading = false;
            // filter paid invoices by selected_year and then aggregate them by month and pass it as data to the prepareChartData function

            this.prepareChartData(invoices, this.transactions);
        },

        async saveTransaction() {
            let toInsert = Object.assign({}, this.editedItem);
            delete toInsert.id;

            if (toInsert.kategorie === this.enum_categories['Ausgaben'] && !toInsert.verteiler) {
                this.$emit('showError', { message: 'Bitte wähle einen Verteiler aus!', timeout: 10000 });
                return;
            }

            if (toInsert.kategorie !== this.enum_categories['Ausgaben']) delete toInsert.verteiler;

            if (this.editedIndex > -1) {
                // updated 

                if (!toInsert.beschreibung) {
                    this.$emit('showError', { message: 'Bitte gib eine Beschreibung ein!', timeout: 10000 });
                    return;
                }

                if (toInsert.betrag === null || toInsert.betrag === undefined || toInsert.betrag === '') {
                    this.$emit('showError', { message: 'Bitte gib einen Betrag an.', timeout: 10000 });
                    return;
                }

                this.inserting_transaction = true;
                let updated = await connector.update(this, 'transaktionen', {
                    datum: toInsert.datum,
                    beschreibung: toInsert.beschreibung,
                    betrag: toInsert.betrag,
                    kategorie: toInsert.kategorie,
                    verteiler: toInsert.verteiler,
                }, this.editedIndex);

                if (!updated) {
                    // error has already been displayed
                    this.inserting_transaction = false;
                    return;
                }

                await this.initialize();
                this.inserting_transaction = false;
                this.close();

            } else {
                // create new

                let toInserts = this.transactions_to_insert.map((transaction) => {
                    let toInsert = JSON.parse(JSON.stringify(transaction.transaction));
                    toInsert.kategorie = this.editedItem.kategorie;
                    toInsert.verteiler = this.editedItem.verteiler;
                    delete toInsert.id;

                    return toInsert;
                })

                // check if all transactions have a description
                for (let i in toInserts) {
                    if (!toInserts[i].beschreibung) {
                        this.$emit('showError', { message: 'Bitte gib überall eine Beschreibung an.', timeout: 10000 });
                        return;
                    }
                }

                // check if all transactions have an amount
                for (let i in toInserts) {
                    if (toInserts[i].betrag === null || toInserts[i].betrag === undefined || toInserts[i].betrag === '') {
                        this.$emit('showError', { message: 'Bitte gib überall einen Betrag an.', timeout: 10000 });
                        return;
                    }
                }

                this.inserting_transaction = true;
                let inserted = await connector.inserts(this, 'transaktionen', toInserts);
                if (inserted === null) {
                    // error has already been shown
                    this.inserting_transaction = false;
                    return;
                }
                
                await this.initialize();
                this.inserting_transaction = false;
                this.close();
            }
        },

        prepareChartData(invoices, transactions) {

            let data = invoices.filter((invoice) => invoice.bezahlt && dayjs(invoice.bezahlt).year() === this.$store.state.selected_year)
                .concat(
                    transactions.filter((transaction) => dayjs(transaction.datum).year() === this.$store.state.selected_year && transaction.kategorie === this.enum_categories['Einnahmen'])
                        .map((transaction) => {
                            return {
                                id: transaction.id,
                                datum: transaction.datum,
                                rechnungs_betrag: transaction.betrag,
                                bezahlt: transaction.datum,
                            }
                        })
                )
                .reduce((acc, invoice) => {
                    let month = dayjs(invoice.bezahlt).month() + 1
                    if (acc[month]) {
                        acc[month].earnings += invoice.rechnungs_betrag
                    } else {
                        acc[month] = {
                            monat: month,
                            earnings: invoice.rechnungs_betrag,
                            spendings: 0
                        }
                    }
                    return acc
                }, {})

            // now filter the transactions by selected_year and then aggregate them by month and pass it as data to the prepareChartData function
            transactions.filter((transaction) => dayjs(transaction.datum).year() === this.$store.state.selected_year && transaction.kategorie === this.enum_categories['Ausgaben'])
                .reduce((acc, transaction) => {
                    let month = dayjs(transaction.datum).month() + 1
                    if (acc[month]) {
                        acc[month].spendings += -transaction.betrag
                    } else {
                        acc[month] = {
                            monat: month,
                            earnings: 0,
                            spendings: -transaction.betrag
                        }
                    }
                    return acc
                }, data)

            // now remove the first level of the object and pass it to the prepareChartData function
            data = Object.values(data)

            let chart_data_earnings = Object.assign({}, this.chart_default_values)
            let chart_data_spendings = Object.assign({}, this.chart_default_values)

            // in case there is no data, getChartData returns 0
            this.options.annotations.points = []

            if (data !== 0) {
                for (let i in data) {
                    chart_data_earnings[data[i].monat] = data[i].earnings
                    chart_data_spendings[data[i].monat] = data[i].spendings
                }
            }

            let chart_array_spendings = [];
            let chart_array_earnings = [];

            let sum = 0;
            let count = 0;
            for (let i = 1; i < 13; i++) {
                chart_array_earnings.push(chart_data_earnings[i]);
                chart_array_spendings.push(chart_data_spendings[i]);

                if (chart_data_earnings[i] || chart_data_spendings[i]) {
                    sum += (chart_data_earnings[i] - chart_data_spendings[i]);
                    count++;
                }
            }

            let y_axis_mean = [];
            // calculate the average
            if (count > 0) {
                y_axis_mean = [
                    {
                        y: sum / count,
                        borderColor: '#000000',
                        strokeDashArray: 6,
                        width: '100%',
                        label: {
                            borderColor: '#000000',
                            // offsetX: -90,
                            style: {
                                fontWeight: 600,
                                fontSize: '12px',
                            },
                            text: (sum >= 0 ? 'Ø Gewinn: ' : 'Ø Verlust: ') + Math.round(sum / count) + ' €',
                        }
                    }
                ];
            }

            let colors = [this.$store.state.theme.green, this.$store.state.theme.red];

            this.series = [
                {
                    name: 'Einnahmen',
                    data: [...chart_array_earnings],
                },
                {
                    name: 'Ausgaben',
                    data: [...chart_array_spendings],
                },
            ]
            
            let options = {
                annotations: {
                    points: this.options.annotations.points,
                    yaxis: y_axis_mean,
                },
                colors: colors,
                fill: {
                    colors: colors,
                },

            }

            // update chart to show annotations and colors
            if (this.$refs.chart) this.$refs.chart.updateOptions(options);


            // donut chart

            let donut_data = this.itemsExpenses.reduce((acc, expense) => {
                acc[expense.text] = 0;
                return acc;
            }, {});

            transactions.filter((transaction) => dayjs(transaction.datum).year() === this.$store.state.selected_year && transaction.kategorie === this.enum_categories['Ausgaben'])
                .forEach((transaction) => {
                let category = this.itemsExpenses.find(expense => expense.id === transaction.verteiler);
                if (category) {
                    donut_data[category.text] += -transaction.betrag;
                }
                });

            // Remove entries where the value is zero
            donut_data = Object.fromEntries(
                Object.entries(donut_data).filter(([key, value]) => value !== 0)
            );


            this.donutChartSeries = Object.values(donut_data);
            this.donutChartOptions.labels = Object.keys(donut_data);
            
            if (this.$refs.donutChart) {
                this.$refs.donutChart.updateOptions(this.donutChartOptions)
            }
        },

    },
    mounted() {
        // code to run when component is mounted here
        this.initialize()
    },

    computed: {

        isFilterActive() {
            return (
                (this.search && this.search.trim() !== '') ||
                this.startDate || this.endDate
            );
        },

        // Calculate the sum of 'Betrag' for the filtered transactions to display as additional row
        sumOfBetrag() {
            return this.fullyFilteredTransactions.reduce(
                (acc, item) => acc + item.betrag,
                0
            );
        },

        dateAndSearchFilteredTransactions() {
            let filtered = [...this.allTransactions];

            // Apply date range filter using dayjs
            if (this.startDate) {
                filtered = filtered.filter(item =>
                    dayjs(item.datum).isSameOrAfter(dayjs(this.startDate), 'day')
                );
            }
            if (this.endDate) {
                filtered = filtered.filter(item =>
                    dayjs(item.datum).isSameOrBefore(dayjs(this.endDate), 'day')
                );
            }

            // Apply search filter
            const search = this.search ? this.search.toLowerCase() : '';
            if (search) {
                filtered = filtered.filter(item => {
                    const dateString = dayjs(item.datum).format('DD.MM.YYYY');
                    const categoryString = item.sortKategorie ? item.sortKategorie.toLowerCase() : '';
                    const beschreibung = item.beschreibung ? item.beschreibung.toLowerCase() : '';
                    const betrag = item.betrag ? item.betrag.toString().toLowerCase() : '';

                    return (
                        beschreibung.includes(search) ||
                        dateString.includes(search) ||
                        categoryString.includes(search) ||
                        betrag.includes(search)
                    );
                });
            }

            return filtered;
        },

        fullyFilteredTransactions() {
            let filtered = [...this.dateAndSearchFilteredTransactions];

            // Apply category filters (income or spending)
            if (this.filter_income) {
                filtered = filtered.filter(
                    transaction => transaction.kategorie === this.enum_categories['Einnahmen']
                );
            } else if (this.filter_spending) {
                filtered = filtered.filter(
                    transaction => transaction.kategorie === this.enum_categories['Ausgaben']
                );
            }

            return filtered;
        },

        nAllTransactions() {
            return this.dateAndSearchFilteredTransactions.length;
        },

        nIncomeTransactions() {
            return this.dateAndSearchFilteredTransactions.filter(
                transaction => transaction.kategorie === this.enum_categories['Einnahmen']
            ).length;
        },

        nSpendingTransactions() {
            return this.dateAndSearchFilteredTransactions.filter(
                transaction => transaction.kategorie === this.enum_categories['Ausgaben']
            ).length;
        },

        computedEarnings() {
            return this.invoices.filter((invoice) => invoice.bezahlt && dayjs(invoice.bezahlt).year() === this.$store.state.selected_year)
                    .reduce((acc, transaction) => acc + transaction.rechnungs_betrag, 0) + 
                   this.transactions.filter((transaction) => dayjs(transaction.datum).year() === this.$store.state.selected_year && transaction.kategorie === this.enum_categories['Einnahmen'])
                    .reduce((acc, transaction) => acc + transaction.betrag, 0)
        },

        computedEarningsCash() {
            return this.invoices.filter((invoice) => invoice.bezahlt && dayjs(invoice.bezahlt).year() === this.$store.state.selected_year && invoice.bar)
                    .reduce((acc, transaction) => acc + transaction.rechnungs_betrag, 0);
        },

        computedEarningsPerTax() {

            let earnings_treatment = this.invoices.filter((invoice) => invoice.bezahlt && dayjs(invoice.bezahlt).year() === this.$store.state.selected_year)
                                                  .reduce((acc, transaction) => acc + transaction.betrag_steuer_behandlung, 0);

            let earnings_supervision = this.invoices.filter((invoice) => invoice.bezahlt && dayjs(invoice.bezahlt).year() === this.$store.state.selected_year)
                                                    .reduce((acc, transaction) => acc + transaction.betrag_steuer_supervision, 0);

            let earnings_kleinunternehmer = this.invoices.filter((invoice) => invoice.bezahlt && dayjs(invoice.bezahlt).year() === this.$store.state.selected_year)
                                                         .reduce((acc, transaction) => acc + transaction.betrag_steuer_kleinunternehmer, 0);

            let earnings_nicht_zugeordnet = this.invoices.filter((invoice) => invoice.bezahlt && dayjs(invoice.bezahlt).year() === this.$store.state.selected_year)
                                                         .reduce((acc, transaction) => acc + transaction.betrag_steuer_nicht_zugeordnet, 0);

            let other_incomes = this.transactions.filter((transaction) => dayjs(transaction.datum).year() === this.$store.state.selected_year && transaction.kategorie === this.enum_categories['Einnahmen'])
                                                 .reduce((acc, transaction) => acc + transaction.betrag, 0);

            return {
                'behandlung': earnings_treatment,
                'supervision': earnings_supervision,
                'kleinunternehmer': earnings_kleinunternehmer,
                'sonstige': other_incomes + earnings_nicht_zugeordnet
            };     
        },

        computedSpendings() {
            return this.transactions.filter((transaction) => dayjs(transaction.datum).year() === this.$store.state.selected_year && transaction.kategorie === this.enum_categories['Ausgaben']).reduce((acc, transaction) => acc - transaction.betrag, 0)
        },

        formTitle() {
            return this.editedIndex === -1 ? 'Neue Einnahme / Ausgabe' : 'Einnahme / Ausgabe bearbeiten'
        },

        computedDateFormatted() {
            return this.formatDate(this.editedItem.datum)
        },

        allTransactions() {
            return this.transactions.filter((transaction) => [this.enum_categories['Einnahmen'], this.enum_categories['Ausgaben']].includes(transaction.kategorie))
                .concat(this.transactionsFromInvoices)
                .map(item => {
                return {
                    ...item,
                    sortKategorie: this.getCategoryDisplayText(item)
                };
            });
        },

        transactionsFromInvoices() {
            let paid_invoices = this.invoices.filter((invoice) => invoice.bezahlt).map((invoice) => {
                let description = invoice.nummer + ' | ';

                if (invoice.fk_klienten_id) {
                    description += invoice.nachname + ' ' + invoice.vorname;
                } else {
                    description += invoice.empfänger_name;
                }

                if (invoice.bar) {
                    description += ' (In bar erhalten)';
                }

                return {
                    id: 'inv_' + invoice.id,
                    datum: invoice.bezahlt,
                    beschreibung: description,
                    nummer: invoice.nummer,
                    bar: invoice.bar,
                    betrag: invoice.rechnungs_betrag,
                    svs_prozent_ruecklagen: invoice.svs_prozent_ruecklagen,
                    kategorie: this.enum_categories['Einnahmen'],
                    deletable: false,
                    inserted_at: invoice.updated_at, // use updated_at as inserted_at due to the fact it is updated when the invoice is marked as paid
                    updated_at: invoice.updated_at
                }
            })
            return paid_invoices
        },
    }
};
</script>

<style>
.year-selector {
    max-width: 200px !important;
}
.custom-header-height .v-data-table__wrapper > table > thead > tr > th {
    height: 48px !important;
}
</style>

<style scoped>
.v-sheet.v-card {
  border-radius: 6px;
}
</style>