<template>
    <div>
        <v-dialog v-model="dialog_anamnese" max-width="1000px" persistent
            :fullscreen="$vuetify.breakpoint.xsOnly || isFullscreen">
            <v-card>
                <v-card-title class="mr-4 pr-0">
                    <span class="text-h5">Vorgeschichte / Anamnese bearbeiten</span>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom open-delay="300" v-if="$vuetify.breakpoint.smAndUp">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="toggleFullscreen" v-bind="attrs" v-on="on">
                                <v-icon>{{ isFullscreen ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon>
                            </v-btn>
                        </template>
                        {{ isFullscreen ? 'Vollbild beenden.' : 'In den Vollbildmodus wechseln.' }}
                    </v-tooltip>
                </v-card-title>
                <v-card-text>
                    <TipTapEditor 
                        ref="editor-anamnese" 
                        :value="edit_anamnese"
                        @input="handleInput('edit_anamnese', $event)"
                        :session="session"
                        @showError="$emit('showError', $event)"
                        @showInfo="$emit('showInfo', $event)"
                    />
                </v-card-text>
                <v-card-actions v-if="isEditable" class="pb-5 px-6">
                    <v-spacer></v-spacer>
                    <v-btn text :color="$store.state.theme.primary" @click="abortAnamnese">Abbrechen</v-btn>
                    <v-btn outlined :color="$store.state.theme.green" @click="saveAnamnese"
                        :loading="saving">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_documentation" max-width="1000px" persistent
            :fullscreen="$vuetify.breakpoint.xsOnly || isFullscreen">
            <v-card>
                <v-tabs v-model="tabs_documentation_type" color="grey darken-4"
                    :background-color="$store.state.theme.background_tabs" grow class="mb-5" show-arrows>
                    <v-tabs-slider color="grey darken-4" />
                    <v-tab>
                        Dokumentation
                        <v-tooltip bottom open-delay="300">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon right v-bind="attrs" v-on="on">
                                    mdi-information-outline
                                </v-icon>
                            </template>
                            <span>
                                Erstelle hier die Dokumentation des Termins. 
                                Diese kann bei Bedarf vom Klienten angefordert und exportiert werden.
                            </span>
                        </v-tooltip>
                    </v-tab>
                    <v-tab>
                        Persönliche Notizen
                        <v-tooltip bottom open-delay="300">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon right v-bind="attrs" v-on="on">
                                    mdi-information-outline
                                </v-icon>
                            </template>
                            <span>
                                Schreibe hier persönliche Notizen zum Termin. 
                                Diese sind nicht Teil der offiziellen Dokumentation und werden nicht exportiert.
                            </span>
                        </v-tooltip>
                    </v-tab>
                    <v-tooltip bottom open-delay="300" v-if="$vuetify.breakpoint.smAndUp">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="toggleFullscreen" class="mt-2 mx-3" v-bind="attrs" v-on="on">
                                <v-icon>{{ isFullscreen ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon>
                            </v-btn>
                        </template>
                        {{ isFullscreen ? 'Vollbild beenden.' : 'In den Vollbildmodus wechseln.' }}
                    </v-tooltip>
                </v-tabs>

                <v-card-text class="pt-1">
                    <v-tabs-items v-model="tabs_documentation_type">
                        <v-tab-item eager>
                            <TipTapEditor 
                                ref="editor-documentation" 
                                :value="documentation"
                                @input="handleInput('documentation', $event)"
                                @showError="$emit('showError', $event)"
                                @showInfo="$emit('showInfo', $event)"
                                :session="session" 
                            />
                        </v-tab-item>
                        <v-tab-item eager>
                            <TipTapEditor 
                                ref="editor-notes" 
                                :value="notes"
                                @input="handleInput('notes', $event)"
                                @showError="$emit('showError', $event)"
                                @showInfo="$emit('showInfo', $event)"
                                :session="session"
                            />
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>

                <v-card-actions class="pb-5 px-6">
                    <v-spacer></v-spacer>
                    <v-btn text :color="$store.state.theme.primary" @click="abortDocumentation">Abbrechen</v-btn>
                    <v-btn outlined :color="$store.state.theme.green" @click="saveDocumentation"
                        :loading="saving">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <p class="d-flex align-center text-h6 text-sm-h5">
            Vorgeschichte / Anamnese
            <v-spacer/>
            <v-btn 
                v-if="clientStatistics && isEditable" 
                @click="downloadDocumentation" 
                :disabled="loading_client_data || error_loading_anamnese || error_loading_documentation"
                outlined
                class="ml-5"
                small
            >
                <v-icon :left="$vuetify.breakpoint.smAndUp">
                    mdi-file-download-outline
                </v-icon>

                <span v-if="$vuetify.breakpoint.smAndUp">Dokumentation herunterladen</span>
            </v-btn>
        </p>
        <v-card>
            <v-card-text class="pb-5 text-body-1 black--text" ref="container-anamnese">
                <v-skeleton-loader v-show="loading_client_data" type="paragraph" transition="fade-transition" />
                <div v-show="!loading_client_data && anamnese" class="p-container" style="white-space: pre-line;"
                    v-html="anamnese">
                </div>
                <span v-show="!loading_client_data && !anamnese && !error_loading_anamnese" class="font-italic">
                    Es wurde noch keine Anamnese für diesen Klienten verfasst.
                </span>
                <span v-show="!loading_client_data && !anamnese && error_loading_anamnese" class="font-italic">
                    <v-icon left>mdi-connection</v-icon>
                    Die Anamnese konnte nicht geladen werden.<br />
                    <v-btn outlined :color="$store.state.theme.green" class="mt-5" @click="reloadWindow">
                        <v-icon left>
                            mdi-refresh
                        </v-icon>
                        Neu Laden
                    </v-btn>
                </span>
            </v-card-text>
            <v-card-actions v-if="!error_loading_anamnese && isEditable" class="justify-end px-5 pb-5">
                <v-btn outlined @click="editAnamnese" :disabled="loading_client_data">Bearbeiten</v-btn>
            </v-card-actions>
        </v-card>

        <div class="d-flex align-center mt-10 mb-5">
            <span class="text-h6 text-sm-h5">Behandlungsverlauf</span>
            <v-btn icon class="ml-4" @click="sort_appointments_descending = !sort_appointments_descending">
                <v-icon>mdi-swap-vertical-bold</v-icon>
            </v-btn>
        </div>

        <v-card v-for="appointment in selectedClientAppointments" :key="appointment.id" class="mb-5">
            <v-card-title>
                <div class="d-flex flex-column">
                    <span class="text-subtitle-1 font-weight-bold">
                        {{ appointment.appointment_count }}. Termin am {{ getFormattedDate(appointment.datum) }} um {{
            getFormattedTime(appointment.uhrzeit) }}
                    </span>
                    <span class="text-subtitle-2" v-if="appointment.bezeichnung">{{ appointment.bezeichnung }}</span>
                </div>
            </v-card-title>
            <v-card-text class="pb-2 text-body-1 black--text">
                <div v-if="appointment.dokumentation && appointment.dokumentation !== '<p></p>'"
                    style="white-space: pre-line;" class="p-container" v-html="appointment.dokumentation">
                </div>
                <span v-else class="font-italic">
                    Dieser Termin wurde noch nicht dokumentiert.
                </span>
                <p v-if="appointment.notizen && appointment.notizen !== '<p></p>'"
                    class="text-subtitle-1 font-weight-bold pb-1 mt-5 mb-0">
                    Persönliche Notizen:
                </p>
                <div v-if="appointment.notizen && appointment.notizen !== '<p></p>'" style="white-space: pre-line;"
                    class="p-container" v-html="appointment.notizen">
                </div>
            </v-card-text>
            <v-card-actions
                :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-column align-end px-5 pb-5' : 'justify-end px-5 pb-5'">
                <v-row no-gutters v-if="appointmentFileIds.length > 0 && appointmentFileIds.includes(appointment.id)">
                    <v-col
                        v-for="(file) in uploadedFiles.filter(file => file.appointment_id === appointment.id)"
                        :key="'appointment-' + file.id" cols="12" md="6" lg="4">
                        <v-hover v-slot="{ hover }">
                            <v-card class="d-flex align-center px-3 py-1 ma-2 file-card" :elevation="hover ? 2 : 0"
                                outlined @click.stop="$emit('openFile', file)">
                                <v-icon v-if="!file.uploading" class="mr-3" :color="file.iconColor">{{ file.icon
                                    }}</v-icon>
                                <v-progress-circular v-else class="mr-3" :size="30" indeterminate color="primary" />
                                <div class="file-info-container d-flex flex-column justify-center flex-grow-1">
                                    <span class="text-caption text-truncate-file">{{ file.name }}</span>
                                    <span>{{ file.size }}</span>
                                </div>
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
                <v-btn v-if="isEditable" text @click="$emit('select-files', appointment.id)">Dateien hinzufügen</v-btn>
                <v-btn v-if="isEditable" outlined @click="editDocumentation(appointment)">Bearbeiten</v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-if="selectedClientAppointments.length === 0">
            <v-card-text v-if="!loading_client_data && !error_loading_documentation"
                class="d-flex align-center text-body-1 black--text">
                <v-icon left large>
                    mdi-information-outline
                </v-icon>
                <span class="font-italic">
                    Es haben noch keine Termine stattgefunden.
                    Um Termine dokumentieren zu können, müssen zuerst Termine erstellt werden.
                </span>
            </v-card-text>
            <v-card-text v-else-if="!loading_client_data && error_loading_documentation"
                class="d-flex align-center text-body-1 black--text">
                <span class="font-italic">
                    <v-icon left>mdi-connection</v-icon>
                    Die Dokumentation konnte nicht geladen werden.<br />
                    <v-btn outlined :color="$store.state.theme.green" class="mt-5" @click="reloadWindow">
                        <v-icon left>
                            mdi-refresh
                        </v-icon>
                        Neu Laden
                    </v-btn>
                </span>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import connector from '../helpers/supabase-connector.js';
import cipher from '../helpers/cipher.js';
import TipTapEditor from '../components/TipTapEditor.vue';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import InspectModule from 'docxtemplater/js/inspect-module';
import { saveAs } from 'file-saver';

export default {
    name: 'ClientDocumentation',
    components: {
        TipTapEditor,
    },
    emits: ['select-files', 'openFile', 'showError', 'showInfo'],
    props: {
        clientId: {
            type: Number,
            required: true
        },
        clientData: {
            type: Object,
            required: true
        },
        uploadedFiles: {
            type: Array,
            default: () => []
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        clientStatistics: {
            type: Object,
            default: null
        },
        session: Object,
    },

    data() {
        return {
            anamnese: null,
            appointments: [],
            error_loading_anamnese: false,
            error_loading_documentation: false,
            loading_client_data: false,
            sort_appointments_descending: true,
            isFullscreen: false,
            tabs_documentation_type: 0,
            documentation: null,
            notes: null,
            documentation_appointment_id: null,
            dialog_anamnese: false,
            dialog_documentation: false,
            edit_anamnese: null,
            saving: false,
        }
    },

    watch: {
        clientId: {
            immediate: true,
            handler(newId) {
                if (newId) {
                    this.loadClientData();
                } else {
                    this.resetData();
                }
            }
        }
    },

    computed: {

        appointmentFileIds() {
            let ids = this.uploadedFiles
                .filter(file => file.appointment_id != null)
                .map(file => file.appointment_id);
            return ids;
        },

        selectedClientAppointments() {
            if (!this.appointments) {
                return [];
            }
            const today = dayjs().startOf('day');
            const filteredAppointments = this.appointments.filter(appointment => dayjs(appointment.datum).isBefore(today.add(1, 'day')));
            if (this.sort_appointments_descending) {
                return filteredAppointments.sort((a, b) => dayjs(b.datum) - dayjs(a.datum)).map((appointment, index) => ({
                    ...appointment,
                    appointment_count: filteredAppointments.length - index
                }));
            } else {
                return filteredAppointments.sort((a, b) => dayjs(a.datum) - dayjs(b.datum)).map((appointment, index) => ({
                    ...appointment,
                    appointment_count: index + 1
                }));
            }
        },
    },

    methods: {

        getFormattedDate(date) {
            return dayjs(date).format('DD.MM.YYYY')
        },

        getFormattedTime(time) {
            return dayjs(time, 'HH:mm:ss').format('HH:mm')
        },

        reloadWindow() {
            window.location.reload(true);
        },

        async downloadDocumentation() {

            try {
                // let div = this.$refs['container-anamnese'];
                // // Replace <br> with \n
                // div.querySelectorAll('br').forEach(br => br.replaceWith('\n'));
                // // Add \n after </p>
                // div.querySelectorAll('p').forEach(p => p.append('\n'));
                // let client_anamnese = div.textContent || div.innerText || "";

                let documentation = {
                    vorname: this.clientData.vorname,
                    nachname: this.clientData.nachname,
                    datum: dayjs().format('DD.MM.YYYY'),
                    erste_einheit: this.clientStatistics.first_appointment,
                    letzte_einheit: this.clientStatistics.last_appointment,
                    anzahl_einheiten: this.clientStatistics.appointment_count + ' (' + this.clientStatistics.appointment_sum + ' Minuten)',
                    frequenz: this.clientStatistics.appointment_frequency + 'x pro Monat',
                    anamnese: this.anamnese ? this.removeTags(this.anamnese) : 'Es wurde keine Anamnese erfasst.',
                    verlauf: this.selectedClientAppointments.map(appointment => {
                        return (appointment.bezeichnung ? appointment.bezeichnung : 'Termin') + ' am ' + dayjs(appointment.datum).format('DD.MM.YYYY') + ' um ' + dayjs(appointment.uhrzeit, 'HH:mm:ss').format('HH:mm') + ':\n' +
                            (appointment.dokumentation ? this.removeTags(appointment.dokumentation) : 'Dieser Termin wurde nicht dokumentiert.') + '\n\n'
                    }).reduce((accumulator, currentValue) => accumulator + currentValue, '')
                }

                let bucket = 'public-templates'
                let filename = 'template-documentation.docx'
                let path = 'documentation/'

                let content = await connector.downloadFile(this, bucket, path, filename);
                if (content === null) {
                    // error has already been shown
                    return;
                }

                let undefined_tags = [];

                const iModule = InspectModule()
                const zip = new PizZip(content)
                const doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                    modules: [iModule],
                    parser: function (tag) {
                        return {
                            get: function (scope) {
                                let result = null;
                                // scope will be {user: "John"}
                                if (tag === ".") {
                                    result = scope;
                                } else {
                                    // Here we use the property "user" of the object {user: "John"}
                                    result = scope[tag.toLowerCase()];
                                }
                                return result;
                            },
                        };
                    },
                    nullGetter: function (part, scopeManager) {
                        if (!part.module) {
                            if (part.type === "placeholder" && part.value.toLowerCase() === "termin_dauer") {
                                return "-";
                            }
                            undefined_tags.push(part);
                            return "";
                        }
                        if (part.module === "rawxml") {
                            return "";
                        }
                        return "";
                    }
                })

                // Render the document (Replace variables)
                doc.render(documentation)

                const blob = doc.getZip().generate({
                    type: "blob",
                    mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                })

                let file_name = ('Dokumentation_' + documentation.nachname + '_' + documentation.vorname + '_' + documentation.datum).replaceAll(' ', '_')
                await saveAs(blob, file_name + ".docx")
            } catch (error) {
                this.$emit('showError', {
                    message: 'Ein Fehler ist beim Erstellen des Dokuments aufgetreten. Bitte versuche es erneut.',
                    timeout: 10000,
                    error: error
                });
            }
        },

        removeTags(str) {
            if ((str === null) || (str === '') || typeof str !== 'string') return '';

            // Replace <br> tags with newline
            str = str.replace(/<br\s*\/?>/gi, '\n');

            // Replace closing <p> tags with newlines
            // the opening tags are replaced afterwards with empty string
            // Optionally, you might want to handle <p> more carefully to avoid too many blank lines
            str = str.replace(/<\/p>/gi, '\n');

            return str.replace(/(<([^>]+)>)/ig, '');
        },

        handleInput(fieldName, value) {
            this[fieldName] = value;
        },

        toggleFullscreen() {
            this.isFullscreen = !this.isFullscreen;
        },

        async loadClientData() {
            // disable all editing possibilities while loading and indicate loading state
            this.loading_client_data = true;

            // reset error indicators
            this.error_loading_anamnese = false;
            this.error_loading_documentation = false;

            // set to empty immediately, so that "Behandlungsverlauf" disappears
            this.appointments = [];

            let anamnese = await connector.getDataOnlyFiltered(this, 'vwdokumentationanamnese', 'eq', 'id', this.clientId, 'id');
            if (anamnese === -1) {
                // error has already been displayed
                this.anamnese = null;
                this.edit_anamnese = null;
                this.error_loading_anamnese = true;
            } else if (anamnese.length > 0) {
                let dec_anamnese = await cipher.decryptObject(this, this.$store.state.aes_key, anamnese[0]);
                this.anamnese = dec_anamnese.anamnese;
                this.edit_anamnese = this.anamnese;
            } else {
                this.anamnese = null;
                this.edit_anamnese = null;
            }

            let appointments = await connector.getDataOnlyFiltered(this, 'vwterminedokumentation', 'eq', 'fk_klienten_id', this.clientId, 'datum', false);
            if (appointments === -1) {
                // error has already been shown, we return, so that 
                this.appointments = [];
                this.error_loading_documentation = true;

            } else {
                this.appointments = await cipher.decryptDataSync(this, appointments);
            }

            // setting at the end, as this triggers the ui change.
            this.loading_client_data = false;
        },

        resetData() {
            this.anamnese = null;
            this.edit_anamnese = null;
            this.appointments = [];
        },

        async saveAnamnese() {
            this.saving = true;

            try {
                // save the anamnese to the database
                let enc_anamnese = await cipher.encryptObject(this.$store.state.aes_key, {
                    anamnese: this.edit_anamnese,
                });

                let updated = await connector.update(this, 'klienten', enc_anamnese, this.clientId);
                if (updated === null) {
                    // error has already been shown
                    this.saving = false;
                    return;
                }
                // so that user sees the edited changes
                this.anamnese = this.edit_anamnese;
                this.$refs['editor-anamnese'].clearHistory();
                this.saving = false;
                this.dialog_anamnese = false;

            } catch (error) {
                await connector.logError(this, {
                    uid: this.session.user.id,
                    message: 'Error saving anamnese ' + error.message,
                });
                this.saving = false;
            }
        },

        editDocumentation(appointment) {
            this.tabs_documentation_type = 0;
            this.documentation = appointment.dokumentation;
            this.notes = appointment.notizen;

            if ('editor-documentation' in this.$refs) {
                this.$refs['editor-documentation'].setContent(this.documentation);
            }

            if ('editor-notes' in this.$refs) {
                this.$refs['editor-notes'].setContent(this.notes);
            }

            this.documentation_appointment_id = appointment.id;
            this.dialog_documentation = true;
        },

        editAnamnese() {
            this.edit_anamnese = this.anamnese;
            if ('editor-anamnese' in this.$refs) {
                this.$refs['editor-anamnese'].setContent(this.edit_anamnese);
            }
            this.dialog_anamnese = true
        },

        abortAnamnese() {
            // reset the anamnese to the last saved value
            this.edit_anamnese = this.anamnese
            this.$refs['editor-anamnese'].clearHistory();
            this.dialog_anamnese = false
        },

        abortDocumentation() {
            this.$refs['editor-documentation'].clearHistory();
            this.$refs['editor-notes'].clearHistory();
            this.dialog_documentation = false;
            this.tabs_documentation_type = 0;
        },

        async saveDocumentation() {
            // save the anamnese to the database
            try {

                this.saving = true;
                let enc_documentation = await cipher.encryptObject(this.$store.state.aes_key, {
                    dokumentation: this.documentation,
                    notizen: this.notes,
                })

                let updated = await connector.update(this, 'termine', enc_documentation, this.documentation_appointment_id);
                if (updated === null) {
                    // error has already been shown
                    this.saving = false;
                    return;
                }

                // update the dokumentation property in this.appointments with the new value where the id matches
                let appointment_index = this.appointments.findIndex(appointment => appointment.id === this.documentation_appointment_id);
                this.appointments[appointment_index].dokumentation = this.documentation;
                this.appointments[appointment_index].notizen = this.notes;

                this.$refs['editor-documentation'].clearHistory();
                this.$refs['editor-notes'].clearHistory();

                // i guess its better to not reset in case something fails, then the user can just try again as the data is still there
                //this.documentation = null
                //this.documentation_appointment_id = null
                this.dialog_documentation = false;
                this.saving = false;
                this.tabs_documentation_type = 0;
            } catch (error) {
                this.saving = false;
                await connector.logError(this, {
                    uid: this.session.user.id,
                    message: 'Error saving documentation ' + error.message,
                });
            }
        },
    },

}
</script>

<style scoped>
.v-sheet.v-card {
  border-radius: 6px;
}
</style>