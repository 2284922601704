<template>
    <v-navigation-drawer app dark :permanent="$vuetify.breakpoint.lgAndUp">
        <v-list nav dense>
            <v-list-item class="pa-4 ma-auto">
                <v-img class="" src="../assets/logo-no-background.svg" />
            </v-list-item>
            <v-list-item class="d-flex justify-center">
                <v-tooltip bottom v-if="$store.getters.isInTrialPeriod && !($store.state.client.beta_circle === true) && !$store.getters.hasPaidSubscription" open-delay="300">
                    <template v-slot:activator="{ on, attrs }" >
                        <v-badge color="orange" overlap>
                            <template v-slot:badge>
                                {{ $store.getters.remainingTrialDays }}
                            </template>
                            <v-chip small color="white" text-color="black" @click="upgrade" v-bind="attrs" v-on="on">
                                <v-icon left small>mdi-account-cog</v-icon>
                                Probezeit:&nbsp;<span class="font-weight-medium">{{ $store.getters.subscriptionTier }}</span>
                                <v-icon right small class="ml-1">mdi-chevron-right</v-icon>
                            </v-chip>
                        </v-badge>
                    </template>
                    <p class="pb-0 mb-0">
                        Du kannst noch {{ $store.getters.remainingTrialDays }} Tage alle Funktionen uneingeschränkt testen.<br/>
                        Danach wird dein Zugang automatisch auf die kostenlose Version (Geringfügig) umgestellt.<br/>
                        Außerdem kannst du hier unsere Abos einsehen und upgraden.
                    </p>
                </v-tooltip>
                <div v-else :class="'gradient-border-container-'+$store.getters.subscriptionTier">
                    <v-tooltip bottom open-delay="300">
                        <template v-slot:activator="{ on, attrs }" >
                            <v-chip class="gradient-chip" small color="white" text-color="black" @click="upgrade" v-bind="attrs" v-on="on">
                                <v-icon left small>mdi-account-cog</v-icon>
                                Abo-Details:&nbsp;<span class="font-weight-medium">{{ $store.getters.subscriptionTier }}</span>
                                <v-icon v-if="!$store.state.client.beta_circle" right small class="ml-1">mdi-chevron-right</v-icon>
                            </v-chip>
                        </template>
                        <p class="pb-0 mb-0" v-if="$store.getters.subscriptionTier !== 'GERINGFÜGIG'">
                            Hier kannst du dein Abo verwalten und deine Rechnungen herunterladen.
                        </p>
                        <p class="pb-0 mb-0" v-else>
                            Hier kannst du unsere Abos einsehen und upgraden.
                        </p>
                    </v-tooltip>
                </div>
            </v-list-item>
        </v-list>

        <NavigationItems :session="session" />
        <slot></slot>

    </v-navigation-drawer>
</template>

<script>
import NavigationItems from './NavigationItems.vue';
import { supabase } from '../supabase';

export default {
    props: ['session'],
    components: { NavigationItems },

    data() {
        return {
        };
    },

    methods: {

        // IMPORTANT: ALSO UPDATE IN APP.VUE
        async upgrade() {
            // if (this.$store.state.client.beta_circle) {
                // do nothing
                // return;
            // }

            if (this.$store.state.client.has_stripe_customer_id) {
                // redirect to customer portal

                let function_name = 'create-stripe-customer-portal';
                if (process.env.NODE_ENV === 'development') {
                    function_name = 'create-stripe-customer-portal-test';
                }

                const { data, error } = await supabase.functions.invoke(function_name);

                if (error) {
                    console.error(error);
                    return;
                }

                window.location.href = data?.url;
            } else {
                this.$router.push('/upgrade');
            }
        },
    },
};
</script>

<style scoped>

.gradient-border-container-GERINGFÜGIG, .gradient-border-container-TEILZEIT, .gradient-border-container-VOLLZEIT{
  position: relative;
  display: inline-block;
  border-radius: 24px; /* Dieser Wert sollte dem border-radius des v-chip entsprechen */
}

.gradient-border-container-GERINGFÜGIG::before {
  content: '';
  position: absolute;
  top: -1px; /* Anpassung basierend auf der Dicke des Rahmens */
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(135deg, #EEEEEE, #A9B8C3);
  z-index: -1;
  border-radius: inherit; /* Erbt den border-radius des übergeordneten Containers */
}

.gradient-border-container-TEILZEIT::before {
  content: '';
  position: absolute;
  top: -1px; /* Anpassung basierend auf der Dicke des Rahmens */
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(135deg, #DBECC0, #81C886);
  z-index: -1;
  border-radius: inherit; /* Erbt den border-radius des übergeordneten Containers */
}

.gradient-border-container-VOLLZEIT::before {
  content: '';
  position: absolute;
  top: -1px; /* Anpassung basierend auf der Dicke des Rahmens */
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(135deg, #EDD1DE, #AFAFEC);
  z-index: -1;
  border-radius: inherit; /* Erbt den border-radius des übergeordneten Containers */
}

.gradient-chip {
  position: relative;
  z-index: 1;
  border-radius: 24px; /* Der tatsächliche border-radius des v-chip */
}
</style>