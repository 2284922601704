<template>
    <div class="d-flex no-gutters flex-column align-center justify-end ma-0 pa-0" >
        <v-dialog v-model="dialog_google_terms" persistent>
            <v-card>
                <v-toolbar dark :color="$store.state.theme.primary">
                    <v-toolbar-title>Nutzungsbedingungen & Datenschutz</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="text-body-1 mt-5 text-justify">
                    <p>
                        Ich bin Sigi, dein Freu(n)d und persönlicher KI-Assistent von Google.
                        Damit ich dir bei deinen Fragen helfen kann, sende ich deine Anfragen an Google.<br/><br/>
                        <b>Datenschutz ist mir aber sehr wichtig!</b><br/>
                        Ich speichere keine Daten von dir und unsere Gespräche werden nicht verwendet, um mich weiter zu verbessern.<br/><br/>

                        <b>Denk bitte daran:</b><br/>
                        Teile aber trotzdem keine sensiblen Daten wie Namen, Adressen, E-Mails oder ähnliches mit mir.
                        
                        Google legt bei ihrer KI großen Wert auf Datenschutz und Sicherheit, deshalb werden die Daten von Google nur so lange gespeichert bis meine KI-Antworten erstellt wurden.
                        Hier findest du weitere Informationen zu den <a href="https://cloud.google.com/vertex-ai/generative-ai/docs/data-governance?hl=de" target="_blank">Datenschutzbestimmungen von Google</a>.
                    </p>

                    <p class="mt-5">
                        Solltest du noch weitere Fragen haben, kannst du dich gerne an uns wenden.
                    </p>
                </v-card-text>
                <v-card-actions class="d-flex justify-center pb-5 mt-0 pt-0">
                    <v-btn text @click="rejectTerms" :color="$store.state.theme.red" class="mr-2">
                        Ablehnen
                    </v-btn>
                    <v-btn @click="acceptTerms" :color="$store.state.theme.green" dark class="ml-2">
                        Akzeptieren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row no-gutters :style="{ width: '100%', height: totalHeight }">
            <v-col cols="12" class="d-flex flex-column" style="height: 100%">
                <!-- Message Container -->
                <div ref="scrollableContainer" class="message-container mt-5" :style="{height: messagesHeight}">
                    <v-row v-for="(message, index) in messages" :key="index" :class="message.isUser ? 'sent' : 'received'" class="d-flex align-center message-item mx-0 my-0">
                        <v-col cols="auto" v-if="!message.isUser">
                            <v-progress-circular v-if="message.loading" indeterminate color="green" size="24"></v-progress-circular>
                            <svg v-else version="1.0" xmlns="http://www.w3.org/2000/svg"
                                width="28pt" height="28pt" viewBox="0 0 1024.000000 1024.000000"
                                preserveAspectRatio="xMidYMid meet">
                                <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
                                fill="#000000" stroke="none">
                                <path d="M4875 9140 c-465 -40 -1012 -222 -1375 -458 -311 -202 -576 -461
                                -774 -757 -54 -80 -160 -287 -201 -390 -15 -38 -41 -115 -57 -170 -54 -186
                                -58 -241 -58 -822 0 -482 -2 -533 -16 -533 -9 0 -57 -14 -108 -31 -221 -73
                                -395 -225 -496 -434 -54 -110 -79 -216 -86 -360 -12 -246 48 -452 184 -633
                                115 -154 260 -250 472 -313 l45 -14 6 -405 c7 -443 9 -468 74 -706 156 -571
                                466 -1031 931 -1380 386 -290 773 -455 1279 -545 150 -27 519 -37 677 -20 128
                                15 371 63 492 98 334 95 685 274 941 480 455 364 752 809 898 1343 67 243 70
                                275 76 724 l6 408 89 29 c299 98 502 313 589 624 24 88 34 319 18 422 -27 175
                                -98 324 -216 451 -113 122 -262 213 -413 252 l-67 17 -6 574 -5 574 -27 110
                                c-90 369 -264 681 -562 1009 -329 361 -897 673 -1444 792 -259 57 -632 84
                                -866 64z m483 -371 c311 -33 592 -113 877 -248 233 -111 409 -230 608 -413
                                286 -261 515 -658 556 -960 5 -41 10 -362 10 -736 l1 -663 -46 3 -47 3 -17 65
                                c-66 253 -261 515 -483 649 -172 104 -340 152 -525 152 -294 -1 -525 -98 -737
                                -308 -130 -130 -217 -276 -269 -454 l-24 -84 -169 -3 -170 -2 -13 57 c-17 78
                                -77 215 -135 310 -67 108 -234 273 -340 335 -106 61 -216 104 -340 129 -83 18
                                -123 20 -235 16 -160 -6 -239 -26 -385 -96 -186 -90 -327 -212 -440 -381 -82
                                -122 -131 -235 -159 -362 -6 -26 -10 -28 -51 -28 l-45 0 -1 178 c-5 535 1
                                1152 12 1221 15 102 70 262 131 385 281 562 857 998 1548 1171 257 64 636 92
                                888 64z m-1242 -2541 c104 -34 179 -83 271 -174 67 -67 89 -98 127 -176 54
                                -110 76 -201 76 -316 0 -217 -75 -388 -237 -543 -131 -125 -274 -182 -453
                                -182 -267 0 -489 144 -615 398 -140 284 -82 602 149 830 91 89 187 146 296
                                177 94 26 287 20 386 -14z m2344 11 c122 -34 204 -83 306 -184 79 -78 98 -103
                                137 -185 139 -288 87 -602 -137 -826 -157 -157 -323 -219 -540 -202 -172 13
                                -313 82 -431 210 -76 81 -119 154 -159 265 -29 83 -31 96 -31 233 0 133 2 151
                                28 225 84 238 262 406 499 469 82 22 239 20 328 -5z m1396 -640 c118 -56 208
                                -164 245 -294 21 -74 18 -283 -5 -360 -43 -145 -159 -275 -288 -324 l-28 -11
                                0 510 c0 281 2 510 5 510 3 0 35 -14 71 -31z m-5450 -479 c0 -297 -4 -500 -9
                                -500 -5 0 -36 14 -69 32 -186 99 -288 340 -246 584 21 124 55 193 136 272 55
                                55 145 112 177 112 7 0 10 -174 11 -500z m2849 263 c34 -161 77 -274 144 -371
                                l42 -63 -3 -332 -3 -332 -33 -68 c-42 -84 -121 -149 -206 -168 -137 -30 -284
                                9 -366 97 -84 92 -85 95 -85 469 l0 330 51 80 c60 95 96 181 119 290 10 44 20
                                83 22 88 2 4 74 7 158 7 l155 0 5 -27z m-2380 -45 c11 -77 60 -223 102 -298
                                121 -221 336 -413 551 -494 139 -52 173 -58 327 -63 127 -4 165 -2 243 16 75
                                16 184 54 280 98 9 4 12 -33 12 -165 0 -198 8 -248 60 -357 136 -290 466 -448
                                795 -380 209 43 357 148 468 333 69 112 81 165 85 375 2 103 7 187 12 187 4 0
                                37 -13 73 -30 139 -63 203 -75 407 -75 173 1 191 2 275 28 370 114 646 401
                                733 764 l27 113 43 0 42 0 0 -414 0 -413 -67 4 c-59 4 -72 2 -102 -19 -70 -48
                                -75 -62 -82 -241 -8 -196 -31 -282 -109 -402 -94 -144 -196 -229 -330 -277
                                -59 -21 -82 -23 -260 -23 l-195 0 -93 34 c-141 50 -213 35 -253 -52 -52 -115
                                8 -217 151 -258 61 -17 64 -22 45 -60 -57 -112 -170 -212 -281 -249 -89 -30
                                -259 -25 -347 9 -89 35 -203 116 -300 215 l-88 89 -79 -85 c-99 -106 -217
                                -188 -323 -223 -63 -21 -98 -26 -175 -26 -202 0 -334 78 -441 259 l-31 53 23
                                8 c12 5 44 17 70 26 89 31 137 90 137 165 0 50 -19 89 -59 123 -51 43 -91 43
                                -221 1 -113 -36 -118 -37 -272 -36 -175 0 -225 10 -341 69 -160 80 -315 282
                                -352 461 -9 39 -15 124 -15 203 0 124 -2 139 -22 169 -34 49 -83 72 -143 67
                                -27 -2 -57 -7 -65 -11 -15 -6 -16 57 -11 702 l1 132 44 0 44 0 7 -52z m2
                                -1689 c44 -63 147 -167 215 -218 178 -130 350 -191 537 -191 l78 0 28 -66 c88
                                -206 263 -384 467 -472 104 -45 192 -62 324 -62 192 0 348 52 502 166 l72 55
                                66 -51 c78 -60 154 -100 261 -137 72 -25 90 -27 238 -28 191 0 243 11 394 86
                                171 86 324 249 390 418 14 36 30 72 34 79 6 8 38 12 101 12 310 0 624 206 796
                                523 l40 72 0 -142 c0 -247 -32 -421 -125 -679 -141 -386 -361 -691 -700 -966
                                -417 -339 -947 -528 -1479 -528 -273 0 -553 47 -804 135 -213 74 -516 241
                                -697 383 -181 143 -376 354 -500 542 -129 196 -208 368 -272 595 -49 175 -62
                                261 -68 472 l-6 188 38 -70 c21 -38 53 -91 70 -116z"/>
                                <path d="M4782 8192 c-34 -10 -90 -59 -108 -94 -11 -22 -15 -49 -12 -90 4 -47
                                10 -64 34 -89 41 -43 76 -59 129 -59 56 0 92 18 133 64 105 120 -21 312 -176
                                268z"/>
                                <path d="M5960 8138 c-52 -15 -90 -46 -111 -92 -36 -79 -20 -157 44 -213 82
                                -72 207 -48 268 51 48 78 4 206 -84 246 -47 22 -66 23 -117 8z"/>
                                <path d="M4102 7673 c-18 -9 -44 -35 -59 -60 -35 -60 -30 -139 12 -183 84 -88
                                203 -71 255 36 64 130 -78 271 -208 207z"/>
                                <path d="M4575 6981 c-136 -61 -136 -251 -1 -312 38 -17 75 -19 542 -19 l501
                                0 33 23 c44 29 69 65 80 113 17 75 -25 163 -93 194 -40 18 -70 20 -532 20
                                -454 0 -493 -2 -530 -19z"/>
                                <path d="M3778 5835 c-138 -72 -180 -269 -81 -384 73 -86 172 -117 274 -88
                                221 65 243 376 34 473 -70 33 -162 32 -227 -1z"/>
                                <path d="M6173 5830 c-201 -115 -173 -403 46 -467 162 -47 323 70 325 236 0
                                76 -19 126 -68 179 -54 60 -104 82 -186 82 -54 0 -74 -5 -117 -30z"/>
                                </g>
                            </svg>
                        </v-col>
                        <v-col class="message-content">
                            <div :class="message.isUser ? 'd-flex justify-end align-center' : 'd-flex justify-start align-center'">
                                <div class="message-bubble" v-if="message.text !== ''" v-html="renderMarkdown(message.text)">
                                </div>
                                <div class="message-bubble" v-else>
                                    <p>Ich überlege...</p>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="auto" v-if="message.isUser">
                            <v-icon>mdi-account-circle</v-icon>
                        </v-col>
                    </v-row>
                </div>
                <!-- Chips Container -->
                <v-row cols="12" class="d-flex align-end justify-center mx-5" ref="chips-input" style="flex-shrink: 0;">
                    <v-slide-group class="">
                        <v-slide-item>
                            <v-chip class="mb-2 mr-3" outlined color="deep-purple accent-4" @click="deleteHistory">Chat zurücksetzen</v-chip>
                        </v-slide-item>
                        <v-slide-item>
                            <v-chip class="mb-2 mr-3" outlined color="deep-purple accent-4" @click="introduce">Wer bist du und was kannst du alles?</v-chip>
                        </v-slide-item>
                    </v-slide-group>
                </v-row>
                <!-- Text Input Container -->
                <v-row cols="12" class="d-flex align-end my-0 px-0" ref="text-input" style="flex-shrink: 0;" >
                    <div max-width="800px" class="input-card">
                        <div class="d-flex chat-actions pa-5">
                            <v-textarea

                                v-model="userInput"
                                label=""
                                placeholder="Stelle eine Frage..."
                                dense
                                shaped
                                filled
                                hide-details
                                :auto-grow="!exceededLines"
                                :rows="exceededLines ? 6 : 1"
                                @keydown.enter="handleEnter" >
                                    <template v-slot:append>
                                        <v-btn class="mb-2" icon color="primary" @click="sendMessage">
                                            <v-icon>mdi-send</v-icon>
                                        </v-btn>
                                    </template>
                                </v-textarea>
                            
                        </div>
                        <p class="text-center text-caption py-0 mt-0 px-5">Ich kann Fehler machen. Überprüfe daher wichtige Informationen.</p>
                    </div>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { supabase } from "../supabase";
import MarkdownIt from "markdown-it";
import connector from "../helpers/supabase-connector.js";

export default {
    props: ["session"],
    data() {
        return {
            dialog_google_terms: false,
            contents: [],
            userInput: "",
            messages: [{ text: "Hallo! Wie kann ich dir helfen?", isUser: false }],
        };
    },

    computed: {
        exceededLines() {
            return this.userInput.split("\n").length > 6;
        },

        messagesHeight() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return "calc(100dvh - 56px - 240px)";
            } else if (this.$vuetify.breakpoint.lgAndUp) {
                return "calc(100dvh - 240px)";
            } else {
                return "calc(100dvh - 64px - 240px)";
            }
        },        

        totalHeight() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return "calc(100dvh - 56px - 13px)";
            } else if (this.$vuetify.breakpoint.lgAndUp) {
                return "calc(100dvh - 13px)";
            } else {
                return "calc(100dvh - 64px - 13px)";
            }
        },
    },

    mounted() {

        const storedContents = localStorage.getItem("assistent-contents");
        if (storedContents) {
            this.contents = JSON.parse(storedContents);
            // convert role: 'MODEL' -> role: 'model' as this is a breaking change (switch from vertex to GoogleApi)
            this.contents = this.contents.map((content) => {
                content.role = content.role.toLowerCase();
                return content;
            });

            this.messages = this.messages.concat(this.contents.map((content) => {
                return { text: content.parts[0].text, isUser: content.role === "user"};
            }));
        }
        this.scrollToBottom();
    },

    methods: {

        extractTextFields(response) {
            const regex = /"text":\s*"([^"]*)"/g;
            let match;
            let response_string = "";
            while ((match = regex.exec(response)) !== null) {
                response_string += match[1];
            }
            return response_string.replaceAll("\\\n", "\n").replaceAll("\\n", "\n");
        },

        acceptTerms() {
            localStorage.setItem('agreed_google_terms_assistent', true);
            this.dialog_google_terms = false;

            if (this.session) { 
                connector.logError(this, {
                    uid: this.session.user.id,
                    message: this.session.user.email +  ' accepted the Google terms assisstent',
                });
            }
        },

        rejectTerms() {
            if (this.session) { 
                connector.logError(this, {
                    uid: this.session.user.id,
                    message: this.session.user.email +  ' REJECTED the Google terms assisstent',
                });
            }
            this.dialog_google_terms = false;
        },

        deleteHistory() {
            this.messages = [{ text: "Hallo! Wie kann ich dir helfen?", isUser: false }];
            this.contents = [];
            localStorage.removeItem("assistent-contents");
        },

        introduce() {
            this.userInput = "Wer bist du und was kannst du alles?";
            this.sendMessage();
        },

        handleEnter(event) {
            if (!event.shiftKey) {
                this.sendMessage();
                event.preventDefault(); // prevent the default action (new line)
            }
        },

        renderMarkdown(text) {
            const md = new MarkdownIt();
            return md.render(text);
        },

        scrollToBottom() {
            this.$nextTick(() => {
                const container = this.$refs.scrollableContainer;
                if (container) container.scrollTop = container.scrollHeight;
            });
        },

        async sendMessage() {

            if (this.userInput.trim() === "") return;

            // check if user has accepted the terms
            const agreed_google_terms_assistent = localStorage.getItem('agreed_google_terms_assistent');
            if (!agreed_google_terms_assistent) {
                this.dialog_google_terms = true;
                return;
            }

            let stored_input = this.userInput.trim();

            this.contents.push({
                role: "user",
                parts: [
                    {
                        text: this.userInput.trim(),
                    },
                ],
            });
            // Add the user message to the chat
            this.messages.push({ text: this.userInput.trim(), isUser: true, loading: false });

            this.scrollToBottom();

            // Clear the input
            this.userInput = "";

            this.messages.push({
                text: "",
                isUser: false,
                loading: true,
            });

            try {
                // const { data, error } = await supabase.functions.invoke("get-text-response", {
                //     body: {
                //         contents: this.contents,
                //         type: "assistentStream",
                //     },
                // });

                var myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + this.session.access_token);
                myHeaders.append("Accept", "*/*");
                myHeaders.append("x-client-info", "supabase-js/2.8.0");

                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    redirect: "follow",
                    body: JSON.stringify({
                        contents: this.contents,
                        type: "assistentStream2",
                    }),
                };

                let response = await fetch("https://qgsfsflpvymafizvkpca.functions.supabase.co/get-text-response/", requestOptions);
                
                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let result = "";
                let displayedText = "";

                this.messages[this.messages.length - 1].loading = false;

                function typeCharacter(component, startIndex, fullText) {
                    return new Promise((resolve) => {
                        let index = startIndex;
                        
                        function type() {
                            try {
                                if (index < fullText.length) {
                                    component.messages[component.messages.length - 1].text = fullText.substring(0, index + 1);
                                    index++;
                                    component.scrollToBottom();
                                    setTimeout(type, 7);
                                } else {
                                    resolve();
                                }
                            } catch (error) {
                                resolve();
                            }
                        }
                        type();
                    });
                }

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;
                    
                    const chunk = decoder.decode(value);
                    const data = JSON.parse(chunk);
                    if (data.text) {
                        result += data.text;
                        await typeCharacter(this, displayedText.length, result);
                        displayedText = result;
                    } else {
                        console.log("failed to parse", data)
                    }
                }
                this.messages[this.messages.length - 1].text = result;

                // Add the complete message to contents after streaming is done
                this.contents.push({
                    role: "model",
                    parts: [
                        {
                            text: result,
                        },
                    ],
                });

                localStorage.setItem("assistent-contents", JSON.stringify(this.contents));

                this.scrollToBottom();

            } catch (error) {
                console.log(error);
                connector.logError(this, {
                    uid: this.session.user.id,
                    message: error,
                });
                this.messages.push({
                    text: "Entschuldigung, ich konnte deine Frage nicht beantworten. Bitte versuche es erneut. Eventuell hilft es den Chat zurückzusetzen.",
                    isUser: false,
                    loading: false,
                });
                this.userInput = stored_input;
            }
        },
    },
};
</script>

<style scoped>

.message-container {
    flex-grow: 1;
    overflow-y: auto;
    background-color: transparent;
     /* Adjust this value based on the combined height of the chips and text input */
}

.message-item {
    display: flex;
    align-items: center;
    padding: 8px 0;
    user-select: text; /* Ensure the whole item can be selected */
}

.message-content {
    flex-grow: 1;
}

.input-card {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 15px !important;
    border-top-left-radius: 15px !important;
    width: 100%;
}

.message-bubble {
    user-select: text; /* Ensure text can be selected */
    padding-top: 15px;
    padding-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-bottom: 0px;
    display: inline-block;
    word-wrap: break-word;
    max-width: 100%; /* Allows the bubble to expand to the full width of its container if needed */
}

/* New CSS to ensure the message bubbles are only as wide as the text */
.sent .d-flex,
.received .d-flex {
    display: inline-flex;
    max-width: 100%;
}

.sent .message-bubble {
    background-color: #e1ffc7;
    text-align: left;
}

.received .message-bubble {
    background-color: #fff;
    text-align: left;
}

</style>

