<template>
    <v-container fluid>
        <v-row v-if="$vuetify.breakpoint.mdAndUp">
            <v-col cols="6" v-for="form in forms" :key="form.id">
                <v-hover v-slot:default="{ hover }">
                    <v-card class="mx-auto file-card" style="height: 100%" @click="activateForm(form.id)" :elevation="hover && form.id ? '5' : '1'">
                        <v-card-title class="d-flex justify-center align-center flex-column my-auto text-h5">
                            {{ form.title }}
                        </v-card-title>
                        <v-card-text class="d-flex justify-center align-center flex-column my-auto">
                            <div v-if="!form.image" class="text-h6 font-weight-regular text-center" v-html="form.subtitle"></div>
                            <div class="d-flex align-center font-weight-light title my-2">
                                <v-icon left>mdi-auto-fix</v-icon>
                                {{ form.description }}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
        <div v-else>
            <v-alert border="bottom" colored-border type="warning" elevation="2" icon="mdi-alert-outline">
                Anträge können nur auf einem Gerät mit größerem Bildschirm erstellt und bearbeitet werden.
                Wechsle daher bitte auf ein Gerät mit größerem Bildschirm um Anträge zu erstellen.
            </v-alert>
        </div>

        <AntragKlinPsy ref="antrag_klinpsy" @showError="$emit('showError', $event)" @showInfo="$emit('showInfo', $event)" :session="session" />
        <AntragPTSVS ref="antrag_pt_svs" @showError="$emit('showError', $event)" @showInfo="$emit('showInfo', $event)" :session="session" />
    </v-container>
</template>

<script>
import AntragKlinPsy from '@/components/AntragKlinPsy.vue';
import AntragPTSVS from '@/components/AntragPTSVS.vue';

export default {
    
    props: ['session'],

    components: {
        AntragKlinPsy,
        AntragPTSVS,
    },
    data() {
        return {
            active_form: null,
            forms: [
                {
                    id: 'antrag_klinpsy',
                    title: 'Antrag auf',
                    subtitle: 'Kostenzuschuss zur Inanspruchnahme von <b>klinisch-psychologischer Behandlungen</b>',
                    image: null,
                    description: 'Formular inklusive KI-gestützter Ausfüllhilfe',
                },
                {
                    id: 'antrag_pt_svs',
                    title: 'Antrag auf',
                    subtitle: 'Kostenzuschuss wegen Inanspruchnahme einer <b>freiberuflich niedergelassenen Psychotherapeut/in</b>',
                    image: null,
                    description: 'Formular inklusive KI-gestützter Ausfüllhilfe',
                },
            ],
        }
    },
    methods: {
        activateForm(formId) {
            if (formId) {
                this.active_form = formId;
                this.$refs[formId].editForm();
            }
    
        },
    }   
}

</script>

<style scoped>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    background-color: #897f7c;
    opacity: 1;
    position: absolute;
    width: 100%;
    transition: box-shadow .3s ease;
}
.file-card {
    transition: box-shadow .3s ease;
    cursor: pointer;
    /* position: relative; */
    /* Ensure the positioning context for the speed dial */
}
.v-sheet.v-card {
  border-radius: 6px;
}
</style>