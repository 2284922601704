<template>
    <div>
    <v-dialog v-model="dialog_google_terms" persistent fullscreen no-click-animation>
        <v-card>
            <v-toolbar dark :color="$store.state.theme.primary">
                <v-toolbar-title>Nutzungsbedingungen & Datenschutz</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="text-body-1 mt-5 text-justify">
                <p>
                    Beim Ausfüllen dieses Antrags unterstützen wir dich mit <b>Vorschlägen</b> von einer Künstlichen Intelligenz (KI) von Google. 
                    Diese Vorschläge sollen dir helfen den Antrag auszufüllen. Es kann jedoch keine Garantie für die Richtigkeit der Vorschläge gegeben werden.
                    Um relevante Vorschläge machen zu können, benötigt die KI ein paar Informationen.<br/><br/>
                    <b>Da der Datenschutz für uns oberste Priorität hat, möchten wir dich darüber informieren, welche Daten die KI von Google benötigt, um relevante Vorschläge für dich machen zu können:</b>
                    <br/>
                    <ul>
                        <li>Die ICD-10 Diagnose(n)</li>
                        <li>Die Angaben zur Intensität der Störung und den Krankheitsverlaufs auf dem Antragsformular</li>
                    </ul>
                    <br/>
                    <b>Bitte achte darauf, keine Namen oder andere persönliche Informationen in diese Felder einzugeben.</b>
                    Verwende am besten allgemeine Beschreibungen, da diese Anträge ja auch von Sachbearbeitern in den Krankenkassen durchgelesen werden.<br/><br/>
                    Google legt bei ihrer KI ebenso großen Wert auf Datenschutz und Sicherheit, deshalb werden die Daten von Google nur so lange gespeichert bis die KI-Vorschläge erstellt wurden.
                    Hier findest du weitere Informationen zu den <a href="https://cloud.google.com/vertex-ai/generative-ai/docs/data-governance?hl=de" target="_blank">Datenschutzbestimmungen von Google</a>.
                    <br/>
                    <b>Wenn du die KI-Vorschläge nicht verwendeset, werden diese Daten auch nicht an Google gesendet.</b>
                </p>
                <v-card outlined>
                    <v-card-title>
                        Hier siehst du, welche Daten für diese KI-Vorschläge an Google gesendet werden
                    </v-card-title>
                    <v-card-text>
                        <p>
                            <b>ICD-10 Diagnose:</b> {{ 
                            formData.icd_diagnosis.length > 0 ? formData.icd_diagnosis.map((diagnosis) =>
                                diagnosis.code).join(', ') : 'Keine Diagnose angegeben' }}
                        </p>
                        <p>
                            <b>Intensität der Störung:</b> {{ formData.field_1 ? formData.field_1 : 'Keine Intensität der Störung angegeben' }}
                        </p>
                        <p>
                            <b>Krankheitsverlauf:</b> {{ formData.field_2 ? formData.field_2 : 'Kein Krankheitsverlauf angegeben' }}
                        </p>
                    </v-card-text>
                </v-card>

                <p class="mt-5">
                    Solltest du noch weitere Fragen haben, kannst du dich gerne an uns wenden.
                </p>
            </v-card-text>
            <v-card-actions class="d-flex justify-center pb-5 mt-0 pt-0">
                <v-btn outlined @click="rejectTerms" :color="$store.state.theme.red" class="mr-2">
                    Ablehnen
                </v-btn>
                <v-btn @click="acceptTerms" :color="$store.state.theme.green" dark class="ml-2">
                    Akzeptieren
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_save_form" max-width="700px" persistent>
            <v-card>
                <v-card-title>Speichern als</v-card-title>
                <v-card-text class="mb-0 pb-0">
                    <v-text-field v-model="localFileName" dense outlined label="Dateiname" />
                    <v-alert prominent text type="info">
                        Der Antrag wird in den Dokumenten des Klienten gespeichert. 
                        Um den Antrag später weiter zu bearbeiten, öffne das Antragsdokument des Klienten und klicke auf "Antrag bearbeiten".
                    </v-alert>
                </v-card-text>
                <v-card-actions class="mt-0 pt-0 px-6 pb-5">
                    <v-spacer></v-spacer>
                    <v-btn text :color="$store.state.theme.primary" :disabled="saving_form" @click="$emit('update:dialog_save_form', false)">
                        Abbrechen
                    </v-btn>
                    <v-btn outlined :color="$store.state.theme.green" :disabled="!file_name" :loading="saving_form" @click="saveForm">
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_prompt_download" persistent max-width="800px">
            <v-card>
                <v-toolbar dark :color="$store.state.theme.primary">
                    <v-toolbar-title>Erinnerung</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pt-5 text-subtitle-1 text-justify">
                    <p>
                        Stelle sicher, dass du den Antrag gespeichert hast, wenn du ihn später weiter bearbeiten möchtest.
                        Anderenfalls gehen deine Änderungen verloren.
                        Du kannst den Antrag ebenso herunterladen, und gegebenenfalls noch direkt Änderungen im PDF Dokument vornehmen.
                    </p>
                </v-card-text>
                <v-card-actions class="pa-5">
                    <v-btn dark :color="$store.state.theme.primary" class="mr-5" @click="$emit('update:dialog_prompt_download', false)">Weiter bearbeiten</v-btn>
                    <v-btn dark :color="$store.state.theme.red" outlined @click="closeDialog">Schließen</v-btn>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="!client">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <v-btn outlined disabled class="mr-5" elevation="0" v-bind="attrs" v-on="on" @click="showSaveDialog">
                                    <v-icon left>mdi-content-save</v-icon>
                                    Speichern
                                </v-btn>
                            </div>
                        </template>
                        <span>Wähle einen Klienten aus um den Antrag zu speichern</span>
                    </v-tooltip>
                    <v-btn v-else outlined class="mr-5" elevation="0" @click="showSaveDialog">
                        <v-icon left>mdi-content-save</v-icon>
                        Speichern
                    </v-btn>
                    <v-btn dark :color="$store.state.theme.green" @click="downloadForm">Herunterladen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import connector from '../helpers/supabase-connector.js';

export default {

    props: {
        session: Object,
        formData: {
            type: Object,
            default: () => ({
                icd_diagnosis: [],
                gaf_value: null,
                field_1: '',
                field_2: '',
                field_3: ''
            })
        },
        dialog_google_terms: {
            type: Boolean,
            default: false
        },
        dialog_save_form: {
            type: Boolean,
            default: false
        },
        dialog_prompt_download: {
            type: Boolean,
            default: false
        },
        client: Object,
        file_name: {
            type: String,
            default: ''
        },
        saving_form: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {}
    },

    computed: {
        localFileName: {
            get() {
                return this.file_name; // Getter returns the prop value
            },
            set(value) {
                this.$emit('update:file_name', value); // Setter emits event to update parent
            }
        }
    },

    methods: {
        acceptTerms() {
            localStorage.setItem('agreed_google_terms', true);
            this.$emit('update:dialog_google_terms', false);

            if (this.session) {
                connector.logError(this, {
                    uid: this.session.user.id,
                    message: this.session.user.email + ' accepted the Google terms',
                });
            }
        },

        rejectTerms() {
            if (this.session) {
                connector.logError(this, {
                    uid: this.session.user.id,
                    message: this.session.user.email + ' REJECTED the Google terms',
                });
            }
            this.$emit('update:dialog_google_terms', false);
        },

        showSaveDialog() {
            this.$emit('update:dialog_save_form', true);
        },

        closeDialog() {
            this.$emit('close-dialog'); 
        },

        async saveForm() {
            this.$emit('save-form'); 
        },

        downloadForm() {
            this.$emit('download-form'); 
        }
    }
}
</script>