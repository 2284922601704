<template>
    <v-dialog v-model="redirectDialog" persistent max-width="500px">
        <v-card>
            <v-card-title class="text-h6">Weiterleitung</v-card-title>
            <v-card-text class="text-body-1 text-justify">
                Dein Google-Kalender Zugriff ist abgelaufen. Bitte klicke auf Erneut verbinden, um den Zugriff zu erneuern.
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="$store.state.theme.primary" text @click="signInWithGoogle">Erneut verbinden</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { supabase } from "../supabase";
import dayjs from "dayjs";
import connector from "../helpers/supabase-connector.js";

export default {
    props: {
        session: {
            type: Object,
            default: null
        },

        customers: {
            type: Array,
            default: () => []
        },

        google_appointments: {
            type: Array,
            default: () => []
        }
    },
    emits: ["showError", "showInfo"],

    data() {
        return {
            color_mapping_events: {
                "1": "#7986CB",
                "2": "#33B679",
                "3": "#8E24AA",
                "4": "#E67C73",
                "5": "#F6BF26",
                "6": "#F4511E",
                "7": "#039BE5",
                "8": "#616161",
                "9": "#3F51B5",
                "10": "#0B8043",
                "11": "#D50000"
            },

            color_mapping_calendars: {
                "1": "#795548",   // Cocoa
                "2": "#E67C73",   // Flamingo
                "3": "#D50000",   // Tomato
                "4": "#F4511E",   // Tangerine
                "5": "#EF6C00",   // Pumpkin
                "6": "#F09300",   // Mango
                "7": "#009688",   // Eucalyptus
                "8": "#0B8043",   // Basil
                "9": "#7CB342",   // Pistachio
                "10": "#C0CA33",  // Avocado
                "11": "#E4C441",  // Citron
                "12": "#F6BF26",  // Banana
                "13": "#33B679",  // Sage
                "14": "#039BE5",  // Peacock
                "15": "#4285F4",  // Cobalt
                "16": "#3F51B5",  // Blueberry
                "17": "#7986CB",  // Lavender
                "18": "#B39DDB",  // Wisteria
                "19": "#616161",  // Graphite
                "20": "#A79B8E",  // Birch
                "21": "#AD1457",  // Radicchio
                "22": "#D81B60",  // Cherry Blossom
                "23": "#8E24AA",  // Grape
                "24": "#9E69AF"   // Amethyst
            },
        
            redirectDialog: false,
            gapi: null,
            gapi_inited: false,
            gapi_reload_count: 0,
            gapi_initializing: false,
            linked_google_account: false,
        };
    },

    async mounted() {
        this.gapi = window.gapi;
        this.linked_google_account = await this.listLinkedIdentities();
        await this.checkAndLoadGapi();
    },

    computed: {
        googleCalenderAuthState() {
            let linked_google_account = this.linked_google_account;
            let linked_google_calendar = this.$store.state.client.google_calendar;
            let provider_refresh_token = localStorage.provider_refresh_token;

            if (provider_refresh_token && linked_google_calendar) {
                return "synced";
            } else if (provider_refresh_token && !linked_google_calendar) {
                return "authorized";
            } else if (linked_google_account) {
                return "linked";
            } else {
                return "unlinked";
            }
        },
    },

    methods: {

        async checkAndLoadGapi() {
            if (this.gapi_inited || this.gapi.client?.calendar?.events) {
                // already loaded
                this.gapi_inited = true;
            } else if (this.gapi_initializing) {
                // Wait for GAPI initialization before proceeding
                const MAX_RETRIES = 20;
                const RETRY_DELAY = 300; // milliseconds
                let retries = 0;

                while (!this.gapi_inited && retries < MAX_RETRIES) {
                    await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
                    retries++;
                }

                if (!this.gapi_inited) {
                    this.$emit('showError', {
                        message: 'Konnte keine Verbindung zum Google Kalender herstellen. Bitte versuche es erneut.',
                        timeout: 5000
                    });
                    return;
                } 
            } else {
                await new Promise((resolve) => {
                    this.gapi.load("client", async () => {
                        await this.initializeGapiClient();
                        resolve();
                    });
                });
            }
        },

        async getCalendars() {

            await this.checkAndLoadGapi();

            let response = null
            try {
                let apiCallFunction = () => this.gapi.client.calendar.calendarList.list();
                response = await this.executeGoogleApiCall(apiCallFunction);

                if (response.status === 200 && response.result.items.length > 0) {
                    return response.result.items.filter((item) => item.accessRole === "owner").map((item) => {
                        let color = null;
                        if (item.colorId && item.colorId in this.color_mapping_calendars) {
                            color = this.color_mapping_calendars[item.colorId]
                        }
                        return {
                            id: item.id,
                            name: item.summary,
                            color: color,
                        }
                    })
                }
            } catch (error) {
                this.$emit('showError', { 
                    message: 'Die Google Kalender konnten nicht geladen werden. Bitte versuche es erneut.',
                    timeout: 10000,
                    error: 'body' in error ? error.body : error
                });
                return []
            }
            return []
        },

        async syncEventWithGoogleCalendar(action, eventData, id, isPrivate) {

            await this.checkAndLoadGapi();

            try {
                let calendarEvent = {};
                let eventIdField = isPrivate ? 'pid' : 'id';
                let appointmentId = id;

                if (isPrivate) {
                    // Build calendarEvent for private appointments
                    calendarEvent = {
                        'calendarId': this.$store.state.client.google_calendar,
                        'start': {
                            'dateTime': eventData.allday ? null : dayjs(eventData.start).format('YYYY-MM-DDTHH:mm:ss'),
                            'date': eventData.allday ? dayjs(eventData.start).format('YYYY-MM-DD') : null,
                            'timeZone': 'Europe/Berlin'
                        },
                        'end': {
                            'dateTime': eventData.allday ? null : dayjs(eventData.end).format('YYYY-MM-DDTHH:mm:ss'),
                            'date': eventData.allday ? dayjs(eventData.end).add(1, 'day').format('YYYY-MM-DD') : null,
                            'timeZone': 'Europe/Berlin'
                        },
                        'summary': eventData.description,
                        'description': 'https://app.zeipsy.com/kalender/?pid=' + id,
                    };
                } else {
                    // Build calendarEvent for client appointments
                    let client_name = this.getClientName(eventData.fk_klienten_id);
                    calendarEvent = {
                        'calendarId': this.$store.state.client.google_calendar,
                        'start': {
                            'dateTime': dayjs(eventData.datum).format('YYYY-MM-DDTHH:mm:ss'),
                            'timeZone': 'Europe/Berlin'
                        },
                        'end': {
                            'dateTime': dayjs(eventData.datum).add(eventData.dauer, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
                            'timeZone': 'Europe/Berlin'
                        },
                        'summary': client_name,
                        'description': 'https://app.zeipsy.com/termine/?id=' + id,
                    };
                }

                if (action === 'add') {
                    // Insert new event
                    let apiCallFunction = () => this.gapi.client.calendar.events.insert(calendarEvent);
                    await this.executeGoogleApiCall(apiCallFunction);
                } else if (action === 'update') {
                    // Find existing event
                    let google_appointment = this.google_appointments.find((appointment) => appointment[eventIdField] === appointmentId);

                    if (!google_appointment) {
                        console.log('No Google appointment found for id', appointmentId);
                        return;
                    }
                    calendarEvent.eventId = google_appointment.eventId;
                    let apiCallFunction = () => this.gapi.client.calendar.events.update(calendarEvent);
                    await this.executeGoogleApiCall(apiCallFunction);
                }
            } catch (error) {
                console.error(error);
                let message = action === 'add'
                    ? 'Der Eintrag wurde erfolgreich gespeichert, konnte jedoch nicht zum Google-Kalender hinzugefügt werden.'
                    : 'Der Eintrag wurde erfolgreich aktualisiert, konnte jedoch nicht im Google-Kalender aktualisiert werden.';
                this.$emit('showError', {
                    message: message,
                    timeout: 10000,
                    error: 'body' in error ? error.body : error
                });
            }
        },

        async deleteEventFromGoogleCalendar(appointmentId, isPrivate) {

            await this.checkAndLoadGapi();

            try {
                let eventIdField = isPrivate ? 'pid' : 'id';
                let googleAppointment = this.google_appointments.find((appointment) => appointment[eventIdField] === appointmentId);
                if (googleAppointment) {

                    let apiCallFunction = () => this.gapi.client.calendar.events.delete({
                        'calendarId': this.$store.state.client.google_calendar,
                        'eventId': googleAppointment.eventId,
                    });
                    await this.executeGoogleApiCall(apiCallFunction);
                } else {
                    console.log(`No matching Google Calendar event found for appointment ID: ${appointmentId}`);
                }
            } catch (error) {
                console.error(`Error deleting appointment from Google Calendar: ${error}`);
                this.$emit('showError', {
                    message: 'Der Termin wurde erfolgreich gelöscht, konnte jedoch nicht aus dem Google-Kalender entfernt werden.',
                    timeout: 10000,
                    error: 'body' in error ? error.body : error
                });
            }
        },

        getClientName(fk_klienten_id) {
            let client_name = 'Klienten-Termin';
            if (fk_klienten_id) {
                let client = this.customers.find((client) => client.fk_klienten_id === fk_klienten_id);
                if (client) {
                    if (this.$store.state.client.google_calendar_anonymize) {
                        if (this.$store.state.client.google_calendar_anonymization_format) {
                            client_name = client.vorname.slice(0, 2) + client.nachname.slice(0, 2);
                        } else {
                            client_name = client.nachname.slice(0, 2) + client.vorname.slice(0, 2);
                        }
                    } else {
                        client_name = client.name;
                    }
                }
            }
            return client_name;
        },

        async deleteGoogleEvent(google_appointment) {

            await this.checkAndLoadGapi();

            let apiCallFunction = () => this.gapi.client.calendar.events.delete({
                calendarId: this.$store.state.client.google_calendar,
                eventId: google_appointment.eventId,
            });
            return await this.executeGoogleApiCall(apiCallFunction);
        },

        async updateGoogleEvent(event) {
            await this.checkAndLoadGapi();

            let apiCallFunction = () => this.gapi.client.calendar.events.update(event);
            return await this.executeGoogleApiCall(apiCallFunction);
        },

        async insertGoogleEvents(events) {
            await this.checkAndLoadGapi();

            try {
                const batch = this.gapi.client.newBatch();
                events.map((event) => {
                    batch.add(this.gapi.client.calendar.events.insert(event));
                });

                // Wrap the batch execution in a promise
                const apiCallFunction = () => new Promise((resolve, reject) => {
                    batch.execute((response) => {
                        // If needed, handle any errors here. 
                        // For now, we assume the entire response is what we want.
                        resolve(response);
                    });
                });

                // Now pass the function that returns a promise to executeGoogleApiCall
                return await this.executeGoogleApiCall(apiCallFunction);
            } catch (error) {
                this.$emit("showError", {
                    message: "Der Eintrag wurde erfolgreich gespeichert, konnte jedoch nicht zum Google-Kalender hinzugefügt werden.",
                    timeout: 10000,
                    error: 'body' in error ? error.body : error
                });
                return null;
            }
        },

        async listLinkedIdentities() {
            for (let attempt = 0; attempt < 2; attempt++) {
                try {
                    // retrieve all identities linked to a user  
                    let result = await supabase.auth.getUserIdentities();
                    if (result && result.data && result.data.identities) {
                        
                        const {
                            data: { identities },
                        } = result;

                        const googleIdentity = identities.find((identity) => identity.provider === "google");
                        if (googleIdentity) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        // handle error
                        if (attempt === 1) { 
                            this.$emit("showError", {
                                message: "Fehler beim Abrufen der verknüpften Google-Accounts. Bitte lade die Seite neu.",
                                timeout: 5000,
                            });
                            return false;
                        }
                        await new Promise(resolve => setTimeout(resolve, 500));
                    }
                } catch (error) {
                    if (attempt === 1) { 
                        this.$emit("showError", {
                            message: "Fehler beim Abrufen der verknüpften Google-Accounts. Bitte lade die Seite neu.",
                            timeout: 5000,
                        });
                        return false;
                    }
                    await new Promise(resolve => setTimeout(resolve, 500));
                }
            }
            
            // Should never reach here but TypeScript might complain without it
            return false;
        },

        async signInWithGoogle() {
            if (this.googleCalenderAuthState === "unlinked") {
                const { data, error } = await supabase.auth.linkIdentity({
                    provider: "google",
                    options: {
                        redirectTo: process.env.NODE_ENV === "development" ? "http://localhost:8080/kalender" : "https://app.zeipsy.com/kalender",
                        scopes: ["https://www.googleapis.com/auth/calendar.calendarlist.readonly", "https://www.googleapis.com/auth/calendar.events"],
                        queryParams: {
                            access_type: "offline",
                            prompt: "consent", // The refresh-token gets returned only immediately after consent.
                            // It will not be re-issued on sessionRefresh or Login.
                            // Therefore, "force" consent on re-login as refresh-token is not in local storage.
                        },
                    },
                });

                if (error) {
                    localStorage.removeItem("scopes");
                    localStorage.removeItem("provider_refresh_token");
                    this.$emit("showError", {
                        message: "Fehler beim Verbinden mit Google.",
                        timeout: 10000
                    });
                }
                if (data) {
                    localStorage.setItem(
                        "scopes",
                        "https://www.googleapis.com/auth/calendar.calendarlist.readonly,https://www.googleapis.com/auth/calendar.events"
                    );
                    // localStorage.setItem('provider_refresh_token', data.provider_refresh_token);
                }
            } else {
                const { data, error } = await supabase.auth.signInWithOAuth({
                    provider: "google",
                    options: {
                        redirectTo: process.env.NODE_ENV === "development" ? "http://localhost:8080/kalender" : "https://app.zeipsy.com/kalender",
                        scopes: ["https://www.googleapis.com/auth/calendar.calendarlist.readonly", "https://www.googleapis.com/auth/calendar.events"],
                        queryParams: {
                            access_type: "offline",
                            prompt: "consent", // The refresh-token gets returned only immediately after consent.
                            // It will not be re-issued on sessionRefresh or Login.
                            // Therefore, "force" consent on re-login as refresh-token is not in local storage.
                        },
                    },
                });

                if (error) {
                    localStorage.removeItem("scopes");
                    localStorage.removeItem("provider_refresh_token");
                    this.$emit("showError", {
                        message: "Fehler beim Verbinden mit Google.",
                        timeout: 10000
                    });
                }
                if (data) {
                    localStorage.setItem(
                        "scopes",
                        "https://www.googleapis.com/auth/calendar.calendarlist.readonly,https://www.googleapis.com/auth/calendar.events"
                    );
                    // localStorage.setItem('provider_refresh_token', data.provider_refresh_token);
                }
            }
        },

        async getRefreshToken(refresh_token) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + this.session.access_token);
            myHeaders.append("Accept", "*/*");
            myHeaders.append("x-client-info", "supabase-js/2.8.0");

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                body: JSON.stringify({
                    refresh_token: refresh_token,
                }),
            };

            let responseJson = {};
            try {
                let response = await fetch("https://qgsfsflpvymafizvkpca.functions.supabase.co/google-refresh-token/", requestOptions);
                responseJson = await response.json();
            } catch (error) {
                connector.logError(this, {
                    uid: this.session.user.id,
                    message: "LOG: Google Calendar: Error during POST request to google-refresh-token function.",
                });
            }

            return responseJson;
        },

        async initializeGapiClient() {
            try {
                // only initialize if we have a refresh token
                if (localStorage.provider_refresh_token) {
                    this.gapi_initializing = true;
                    let accessToken = await this.getRefreshToken(localStorage.provider_refresh_token);
                    await this.gapi.client.init({
                        apiKey: "AIzaSyBVojXCiR8LbVSlFt94GU0-O_yShuQpRTs",
                        discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
                    });
                    this.gapi.client.setToken({ access_token: accessToken.access_token });

                    // Confirm the Calendar API is loaded (using the newer gapi.client.load)
                    await this.gapi.client.load("https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest");

                    // Double-check that calendar events is available
                    if (!this.gapi.client.calendar || !this.gapi.client.calendar.events) {
                        console.error("Calendar API not available after initialization.");
                        connector.logError(this, {
                            uid: this.session.user.id,
                            message: "LOG: Calendar API not available after initialization.",
                        });
                        this.gapi_inited = false;
                    } else {
                        this.gapi_inited = true;
                    }
                    this.gapi_initializing = false;
                } else {
                    // check if a calendar is already set, if so, show the reconnect dialog
                    if (this.$store.state.client.google_calendar) {
                        this.redirectDialog = true;
                        connector.logError(this, {
                            uid: this.session.user.id,
                            message: "LOG: Google Calendar: Refresh token not found although calender is set. Prompting user to reconnect.",
                        });
                    }
                }
            } catch (error) {
                console.log(error);
                this.$emit("showError", {
                    message: "Fehler beim Initialisieren von Google-API.",
                    timeout: 10000
                });
            }
        },

        async executeGoogleApiCall(apiCallFunction) {
            const MAX_RETRIES = 2;
            let attempt = 0;

            while (attempt < MAX_RETRIES) {
                try {
                    const response = await apiCallFunction();
                    // Reset any reload count or flags if necessary
                    this.gapi_reload_count = 0;
                    return response;
                } catch (error) {
                    console.log(error);
                    attempt = attempt + 1;
                    // Handle different error statuses
                    if (error.status === 401 || error.status === 404) {
                        // Unauthorized or Not Found - possibly invalid or expired access token
                        if (localStorage.provider_refresh_token) {
                            if (this.gapi_reload_count > 0) {
                                // Already tried reloading, prompt user to reconnect
                                this.redirectDialog = true;
                                connector.logError(this, {
                                    uid: this.session.user.id,
                                    message: "LOG: Google Calendar: Authentication loop detected. Prompting user to reconnect.",
                                });
                                console.error(error);
                                return null;
                            }
                            await new Promise((resolve) => {
                                this.gapi.load("client", async () => {
                                    await this.initializeGapiClient();
                                    resolve();
                                });
                            });
                            this.gapi_reload_count += 1;
                            continue;
                        } else {
                            // No refresh token available, prompt user to reconnect
                            this.redirectDialog = true;
                            connector.logError(this, {
                                uid: this.session.user.id,
                                message: "LOG: Google Calendar: Refresh token not found. Prompting user to reconnect.",
                            });
                            console.error(error);
                            return null;
                        }
                    } else if (error.status === 403) {
                        // Forbidden - insufficient permissions
                        this.redirectDialog = true;
                        connector.logError(this, {
                            uid: this.session.user.id,
                            message: "LOG: Google Calendar: Insufficient permissions. Prompting user to reconnect.",
                        });
                        console.error(error);
                        return null;
                    } else {
                        // Other errors
                        console.error(error);
                        throw error;
                    }
                }
            }
        },

        async getCalendarEvents(calendar_id) {
            await this.checkAndLoadGapi();

            let response = null;
            try {
                const request = {
                    calendarId: calendar_id,
                    timeMin: dayjs().subtract(2, "month").toISOString(),
                    showDeleted: false,
                    singleEvents: true,
                    maxResults: 250,
                    orderBy: "startTime",
                };

                const apiCallFunction = () => this.gapi.client.calendar.events.list(request);
                response = await this.executeGoogleApiCall(apiCallFunction);

                if (response) {
                    return response.result.items.map((appointment) => {
                        let color = null;
                        if (appointment.colorId &&  appointment.colorId in this.color_mapping_events) {
                            color = this.color_mapping_events[appointment.colorId]
                        }

                        let id = null;
                        let pid = null;
                        if (appointment.description && appointment.description.includes('zeipsy.com/termine/?id=')) {
                            id = parseInt(appointment.description.split('id=')[1]);
                        } else if (appointment.description && appointment.description.includes('zeipsy.com/kalender/?pid=')) {
                            pid = parseInt(appointment.description.split('pid=')[1]);
                        }

                        return {
                            color: color,
                            start: appointment.start.date ? dayjs(appointment.start.date).format('YYYY-MM-DD') : dayjs(appointment.start.dateTime).format('YYYY-MM-DDTHH:mm'),
                            end: appointment.end.date ? dayjs(appointment.end.date).subtract(1, 'day').format('YYYY-MM-DD') : dayjs(appointment.end.dateTime).format('YYYY-MM-DDTHH:mm'),
                            name: appointment.summary,
                            type: "google",
                            id: id,
                            pid: pid,
                            eventId: appointment.id,
                        };
                    });
                }
                return [];
            } catch (err) {
                console.error(err);
                this.$emit('showError', {
                    message: 'Konnte Google Kalender Einträge nicht abrufen. Bitte versuche es erneut.',
                    timeout: 10000,
                    error: 'body' in err ? err.body : err
                });
                return [];
            }
        },
    },
};
</script>
