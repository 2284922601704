<template>
    <v-container fluid>

        <v-dialog v-model="prompt_name" max-width="600px">
            <v-card>
                <v-card-title class="text-h5">Dein Name</v-card-title>
                <v-card-text>
                    <p class="text-body-1 text-justify">
                        Bitte gib deinen Namen ein, damit deine KollegInnen wissen, wer sie eingeladen hat.
                        Wenn du deinen Namen nicht eingibst, wird deine E-Mail Adresse verwendet.
                    </p>
                    <v-text-field v-model="name" label="Dein Name" outlined dense></v-text-field>
                    <div class="d-flex justify-end">
                        <v-btn :color="$store.state.theme.primary" text @click="prompt_name = false">
                            Abbrechen
                        </v-btn>
                        <v-btn class="ml-3" :color="$store.state.theme.green" outlined @click="sendInvite">
                            Senden
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-row :no-gutters="$vuetify.breakpoint.xsOnly" :class="$vuetify.breakpoint.xsOnly ? 'pt-5' : ''">
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="d-flex flex-column wrap pb-5">
                <div class="text-h5">
                    <v-icon color="blue" class="mr-3">mdi-message-star</v-icon>
                    Teile dein Feedback
                </div>
                <span class="text-subtitle-1 text-justify">
                    Teile uns mit, was dir gefällt und was wir verbessern können.
                    Lass uns wissen, wenn du dir eine Funktion wünschst oder wenn du einen Fehler gefunden hast.
                    Wir freuen uns über jedes Feedback!
                </span>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8">
                <v-card class="pa-5">
                    <v-textarea v-model="feedback" auto-grow label="Dein Feedback" outlined dense
                        class="mb-0 pb-0"></v-textarea>

                    <div class="d-flex justify-end mt-0 pt-0">
                        <v-btn :loading="loading_feedback" :color="$store.state.theme.green" outlined @click="sendFeedback">
                            <v-icon left>mdi-send-circle</v-icon>
                            Senden
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <v-divider class="my-5"></v-divider>

        <v-row :no-gutters="$vuetify.breakpoint.xsOnly">
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="d-flex flex-column wrap pb-5">
                <div class="text-h5">
                    <v-icon color="red" class="mr-3">mdi-heart</v-icon>
                    Dein Empfehlungscode
                </div>
                <span class="text-subtitle-1 text-justify">
                    Erhalte <b>50€</b> für jede erfolgreiche Empfehlung von ZEIPSY.
                    Deine KollegInnen erhalten außerdem <b>10% Rabatt</b> auf ihr Jahres-Abo.
                    Dieser Code ist nur mit einem Jahres-Abo einlösbar.
                    Die 50€ werden dir für deine nächste Abo-Zahlung gutgeschrieben, sobald deine Empfehlung ein
                    Jahres-Abo abgeschlossen hat.
                </span>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8">
                <v-card class="pa-5">
                    <v-text-field v-if="$store.state.client.empfehlungscode" class="mt-5"
                        v-model="$store.state.client.empfehlungscode" label="Dein Empfehlungscode" readonly outlined />

                    <p v-if="$store.state.client.empfehlungscode" class="text-h6">
                        KollegInnen einladen
                    </p>
                    <p v-if="$store.state.client.empfehlungscode">
                        Sende deinen Empfehlungscode direkt an deine KollegInnen und erhalte 50€ für jede erfolgreiche
                        Empfehlung.
                        Trenne mehrere E-Mail Adressen mit einem Komma.
                        Wir verwenden die E-Mail Adressen nur für den Versand der Empfehlung und speichern sie nicht.
                    </p>
                    <div v-if="$store.state.client.empfehlungscode" class="d-flex align-center">
                        <v-text-field v-model="invite_emails" label="E-Mail Adresse(n) eintragen" outlined dense class="mr-3" hide-details />
                        <v-btn outlined :color="$store.state.theme.green" @click="checkEmailsAndPromptName" :loading="sending_invites">
                            <v-icon left>mdi-send-circle</v-icon>
                            Senden
                        </v-btn>
                    </div>

                    <div v-if="$store.state.client.empfehlungscode" class="d-flex align-center mt-3">
                        <span class="text-overline mr-3">
                            E-Mail Adresse(n):
                        </span>
                        <v-chip v-if="parsedInviteEmails.length === 0" small class="mt-2 mb-2 mr-2">
                            <v-icon left>
                                mdi-arrow-up-bold-outline
                            </v-icon>
                            Gib oben die E-Mail Adressen ein.
                        </v-chip>
                        <v-chip v-for="(email, index) in parsedInviteEmails" :key="index" small class="mt-2 mb-2 mr-2">
                            <v-icon left>
                                mdi-email-heart-outline
                            </v-icon>
                            {{ email }}
                        </v-chip>
                    </div>

                    <v-alert v-if="!$store.state.client.empfehlungscode" class="mt-5" outlined type="info">
                        Sobald du ein Abo abgeschlossen hast, wird dir hier dein persönlicher Empfehlungscode
                        angezeigt.
                    </v-alert>
                    <v-row class="mt-2">
                        <v-col cols="12">
                            <v-alert type="info" icon="mdi-piggy-bank-outline" color="green" dense text prominent>
                                Du hast <b>{{ referralCount }}</b> Empfehlungen gemacht.
                                Insgesamt hast du somit <b>{{ savedAmount }}€</b> gespart.
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { supabase } from '../supabase';

export default {

    props: ['session'],

    data() {
        return {
            feedback: '',
            loading_feedback: false,
            invite_emails: null,
            sending_invites: false,
            prompt_name: false,
            name: null,
        }
    },

    computed: {

        parsedInviteEmails() {
            if (this.invite_emails === null) {
                return [];
            }
            let emails = this.invite_emails.split([',']).map(email => email.trim()); // Split by comma and trim spaces
            // iterate over emails and check if they are valid using /.+@.+\.[A-Za-z]{2,}/ expression.
            return emails.filter(email => /.+@.+\.[A-Za-z]{2,}/.test(email));
        },

        referralCount() {
            if (this.$store.state.client.empfehlungen === null) {
                return 0;
            }
            return this.$store.state.client.empfehlungen;
        },

        savedAmount() {
            if (this.$store.state.client.empfehlungen === null) {
                return 0;
            }
            return this.$store.state.client.empfehlungen * 50;
        },
    },

    methods: {

        async sendFeedback() {
            this.loading_feedback = true;

            try {

                const { data, error } = await supabase.functions.invoke('send-mail', {
                    body: {
                        emailType: 'feedback',
                        feedback: this.feedback,
                        email: this.session.user.email,
                    },
                });

                if (error) {
                    this.$emit('showError', {
                        message: 'Feedback konnte nicht gesendet werden. Bitte versuche es später erneut.',
                        timeout: 5000,
                    });
                    this.loading_feedback = false;
                    return;
                }

            } catch (error) {
                this.$emit('showError', {
                    message: 'Feedback konnte nicht gesendet werden. Bitte versuche es später erneut.',
                    timeout: 5000,
                });
                this.loading_feedback = false;
                return;
            }

            this.$emit('showInfo', {
                message: 'Vielen Dank für dein Feedback!',
                timeout: 5000,
            });

            this.loading_feedback = false;
            this.feedback = '';
        },

        async checkEmailsAndPromptName() {
            if (this.parsedInviteEmails.length === 0) {
                this.$emit('showError', {
                    message: 'Bitte gib mindestens eine gültige E-Mail Adresse ein.',
                    timeout: 5000,
                });
                return;
            }

            this.prompt_name = true;
        },

        async sendInvite() {

            this.prompt_name = false;
            this.sending_invites = true;

            try {

                for (let email of this.parsedInviteEmails) {
                    const { data, error } = await supabase.functions.invoke('send-mail', {
                        body: {
                            emailType: 'invite',
                            to: email,
                            from: this.session.user.email,
                            code: this.$store.state.client.empfehlungscode,
                            fromName: this.name || this.session.user.email,
                        },
                    });

                    if (error) {
                        this.$emit('showError', {
                            message: 'Einladung konnte nicht gesendet werden. Bitte versuche es später erneut.',
                            timeout: 5000,
                        });
                        return;
                    }
                }

            } catch (error) {
                this.$emit('showError', {
                    message: 'Einladung konnte nicht gesendet werden. Bitte versuche es später erneut.',
                    timeout: 5000,
                });
                return;
            }

            this.$emit('showInfo', {
                message: 'Die Einladung(en) wurden erfolgreich versendet! Danke für deine Unterstützung! <3',
                timeout: 5000,
            });

            this.invite_emails = null;
            this.sending_invites = false;
        },
    },
}
</script>

<style scoped>
.v-sheet.v-card {
  border-radius: 6px;
}
</style>